import { derived } from 'overmind';
import { DictionaryTypeEnum } from 'utils/contstants';
import { IDictionary, IDictionaryValue } from 'types/dictionaries';
import { IToken_Obj } from 'types/users';
import { List } from 'types/list';
import { EventDetails } from 'types/events';
import { ICar, TCarMark, TCarModel } from 'types/cars';
import { IMaintenance } from 'types/maintenance';
import { IUser } from 'types/users';
import { IOrganization } from 'types/organizations';

export type RootState = {
  isLoggedIn: boolean;
  auth: null | IToken_Obj;
  user: any;
  isFetching: boolean;
  dictionary: List<IDictionary>;
  events: List<EventDetails>;
  cars: List<ICar>;
  users: List<IUser>;
  carMarks: List<TCarMark>;
  carModels: List<TCarModel>;
  maintenances: List<IMaintenance>;
  organizations: List<IOrganization>;
  eventChampionshipDictionary: IDictionaryValue[];
  eventTypeDictionary: IDictionaryValue[];
  eventStatusDictionary: IDictionaryValue[];
  maintenanceStatusDictionary: IDictionaryValue[];
  taskStatusDictionary: IDictionaryValue[];
  measurementTypeDictionary: IDictionaryValue[];
  taskTypeDictionary: IDictionaryValue[];
  carPartStatusDictionary: IDictionaryValue[];
  userRole: null | string;
  carPartStockDictionary: IDictionaryValue[];
};

export type DictionaryValuesStateKeys = keyof Pick<
  RootState,
  | 'eventChampionshipDictionary'
  | 'eventTypeDictionary'
  | 'eventStatusDictionary'
  | 'maintenanceStatusDictionary'
  | 'taskStatusDictionary'
  | 'taskTypeDictionary'
  | 'carPartStatusDictionary'
  | 'measurementTypeDictionary'
  | 'carPartStockDictionary'
>;

const getDictionaryValues = ({ dictionary }: RootState, typeKey: DictionaryTypeEnum): IDictionaryValue[] =>
  dictionary.data.find((item) => item.type === typeKey)?.values || [];

const initialState: RootState = {
  isLoggedIn: false,
  auth: null,
  user: null,
  isFetching: false,
  dictionary: { data: [], total: 0 },
  events: { data: [], total: 0 },
  cars: { data: [], total: 0 },
  users: { data: [], total: 0 },
  maintenances: { data: [], total: 0 },
  organizations: { data: [], total: 0 },
  carMarks: { data: [], total: 0 },
  carModels: { data: [], total: 0 },
  userRole: null,
  eventChampionshipDictionary: derived((state: any) =>
    getDictionaryValues(state, DictionaryTypeEnum.EVENT_CHAMPIONSHIP)
  ),
  eventTypeDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.EVENT_TYPE)),
  eventStatusDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.EVENT_STATUS)),
  maintenanceStatusDictionary: derived((state: any) =>
    getDictionaryValues(state, DictionaryTypeEnum.MAINTENANCE_STATUS)
  ),
  measurementTypeDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.MEASUREMENT_TYPE)),
  taskStatusDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.TASK_STATUS)),
  taskTypeDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.TASK_TYPE)),
  carPartStatusDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.CAR_PART_STATUS)),
  carPartStockDictionary: derived((state: any) => getDictionaryValues(state, DictionaryTypeEnum.CAR_PART_STOCK)),
};

export default initialState;
