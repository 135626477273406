import { dateFormatter, linkFormatter, linkViewFormatter } from '../../../utils/columns';
import { GridColumns, GridFilterInputValue, GridFilterItem } from '@mui/x-data-grid';
import { SelectFieldOption } from '../../../components/DynamicForm';

type Options = {
  typeOptions: SelectFieldOption[];
  championshipOptions: SelectFieldOption[];
  statusOptions: SelectFieldOption[];
};
const filterOperators = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (filterItem.value == null || filterItem.value === '') {
        return null;
      }
      return ({ value }: any): boolean => {
        if (typeof value === 'object') {
          return filterItem.value === (value as { value: any; label: string }).value;
        }
        return filterItem.value === value;
      };
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: 'singleSelect' },
  },
];

export const buildEventListColumns = (options: Options): GridColumns => [
  {
    field: 'code',
    headerName: 'Code',
    renderCell: ({ row }) => linkFormatter(row.code, 'events', row.id),
    width: 100,
    sortable: false,
    filterable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    filterable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 100,
    sortable: false,
    type: 'singleSelect',
    filterable: true,
    filterOperators,
    valueOptions: options.typeOptions,
    valueFormatter: ({ row }) => (row as any)?.type?.name ?? '-',
  },
  {
    field: 'dateStart',
    headerName: 'Start date',
    width: 150,
    type: 'date',
    valueFormatter: ({ value }) => dateFormatter(value as string),
    sortable: false,
    filterable: false,
  },
  {
    field: 'dateEnd',
    headerName: 'Due date',
    width: 150,
    type: 'date',
    valueFormatter: ({ value }) => dateFormatter(value as string),
    sortable: false,
    filterable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 150,
    type: 'date',
    valueFormatter: ({ value }) => dateFormatter(value as string),
    sortable: false,
    filterable: false,
  },
  {
    field: 'championship',
    headerName: 'Championship',
    width: 150,
    valueFormatter: ({ value }) => (value as any)?.name ?? '-',
    sortable: false,
    type: 'singleSelect',
    filterable: true,
    filterOperators,
    valueOptions: options.championshipOptions,
  },
  {
    field: 'trackName',
    headerName: 'Track',
    width: 150,
    sortable: false,
    type: 'string',
    filterable: true,
    valueFormatter: ({ value }) => value ?? '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    valueFormatter: ({ value }) => (value as any)?.name ?? '-',
    sortable: false,
    type: 'singleSelect',
    filterable: true,
    filterOperators,
    valueOptions: options.statusOptions,
  },
  {
    width: 100,
    field: '_view',
    filterable: false,
    sortable: false,
    headerName: 'View',
    renderCell: ({ row }) => linkViewFormatter(row.id, 'events'),
  },
  {
    width: 100,
    field: '_edit',
    filterable: false,
    sortable: false,
    headerName: 'Edit',
    renderCell: ({ row }) => linkViewFormatter(row.id, 'events', true),
  },
];
