// import { JSEncrypt } from 'jsencrypt';

export class BaseSecureStorage {
  // private static encrytKey = (key: string): string | boolean => {
  //   const encrypt = new JSEncrypt();
  //   encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
  //   MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlOJu6TyygqxfWT7eLtGDwajtN
  //   FOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76
  //   xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4
  //   gwQco1KRMDSmXSMkDwIDAQAB
  //   -----END PUBLIC KEY-----`);
  //   const encrypted = encrypt.encrypt(key);
  //   return encrypted;
  // };

  // private static decrypKey = (key: string): string | boolean => {
  //   const decrypt = new JSEncrypt();
  //   decrypt.setPrivateKey(`-----BEGIN RSA PRIVATE KEY-----
  //   MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQ
  //   WMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNR
  //   aY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQAB
  //   AoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fv
  //   xTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeH
  //   m7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd
  //   8XJTSdcIX4a3gy3GGCJxOzv16XHxD03GW6UNLmfPwenKu+cdrQeaqEixrCejXdAF
  //   z/7+BSMpAkEA8EaSOeP5Xr3ZrbiKzi6TGMwHMvC7HdJxaBJbVRfApFrE0/mPwmP5
  //   rN7QwjrMY+0+AbXcm8mRQyQ1+IGEembsdwJBAN6az8Rv7QnD/YBvi52POIlRSSIM
  //   V7SwWvSK4WSMnGb1ZBbhgdg57DXaspcwHsFV7hByQ5BvMtIduHcT14ECfcECQATe
  //   aTgjFnqE/lQ22Rk0eGaYO80cc643BXVGafNfd9fcvwBMnk0iGX0XRsOozVt5Azil
  //   psLBYuApa66NcVHJpCECQQDTjI2AQhFc1yRnCU/YgDnSpJVm1nASoRUnU8Jfm3Oz
  //   uku7JUXcVpt08DFSceCEX9unCuMcT72rAQlLpdZir876
  //   -----END RSA PRIVATE KEY-----`);
  //   const decrypted = decrypt.decrypt(key || '');
  //   return decrypted;
  // };

  public static secureSaveAsync = (key: string, value: string): void => {
    if (key === 'ACCOUNTS') {
      const data = JSON.parse(value)?.map((item: any) => ({
        ...item,
        privateKey: item.privateKey,
      }));
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, value);
    }
  };

  public static secureRetrieveAsync = async (key: string): Promise<any> => {
    try {
      if (key === 'ACCOUNTS') {
        const data = localStorage.getItem(key);
        if (!data) return data;
        const decryptedData = JSON.parse(data)?.map((item: any) => ({
          ...item,
          privateKey: item.privateKey,
        }));
        return JSON.stringify(decryptedData);
      } else {
        return localStorage.getItem(key);
      }
    } catch (e) {
      return null;
    }
  };

  public static removeKey = async (key: string): Promise<any> => {
    try {
      const result = localStorage.removeItem(key);
      return result;
    } catch (e) {
      return null;
    }
  };
}
