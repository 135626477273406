import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { USER_URL } from 'store/api';

import { ListSection } from 'sections/ListSection';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { USER_FORM_CONFIG } from './user-form.config';
import { USER_DETAILS_CONFIG } from './user-details.config';

export const Users = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <ListSection url={USER_URL} columnName="users" />;
  } else if (content === PageContenEnum.SHOW) {
    return <ShowSection url={USER_URL} title="User Details" config={USER_DETAILS_CONFIG} id={id} />;
  } else if (content === PageContenEnum.EDIT) {
    return <EditSection id={id} url={USER_URL} title="Edit User" config={USER_FORM_CONFIG} />;
  }

  return <AddSection title="Create User" config={USER_FORM_CONFIG} dataType="user" />;
};
