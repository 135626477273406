import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { ORGANIZATION_URL } from 'store/api';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { ORGANIZATION_FORM_CONFIG } from './organization-form.config';
import { OrganizationList } from './OrganizationList';
import { ORGANIZATION_DETAILS_CONFIG } from './organization-details.config';
import TransactionService from 'services/symbol/TransactionService';
import { Api } from 'utils/Api';

export const Organizations = () => {
  const { content, id }: any = useParams();

  const handleSuccess = async (v: any, onFinally: () => void) => {
    const { account } = await TransactionService.createWallet();
    const data = {
      entity: 'organization',
      entityId: v.id,
      publicKey: account.publicKey,
      privateKey: account.privateKey,
    };
    Api.post('/wallet', data).finally(() => onFinally());
  };

  if (!content || content === PageContenEnum.LIST) {
    return <OrganizationList />;
  } else if (content === PageContenEnum.SHOW) {
    return (
      <ShowSection url={ORGANIZATION_URL} id={id} config={ORGANIZATION_DETAILS_CONFIG} title="Organization Details" />
    );
  } else if (content === PageContenEnum.EDIT) {
    return <EditSection id={id} url={ORGANIZATION_URL} title="Edit Organization" config={ORGANIZATION_FORM_CONFIG} />;
  }

  return <AddSection title="Create Organization" config={ORGANIZATION_FORM_CONFIG} successCreatedCB={handleSuccess} />;
};
