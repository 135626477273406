import React from 'react';
import { SelectFormControl } from './SelectFormControl';
import { TextFormControl } from './TextFormControl';
import { UploaderFormControl } from './UploaderFormControl';
import {
  BaseValues,
  CarPartAllocationConfig,
  FormFieldConfig,
  SelectFormFieldConfig,
  TextFieldConfig,
  UploadFormFieldConfig,
} from '../config';
import { FormikErrors } from 'formik/dist/types';
import { CarPartAllocationControl } from './CarPartAllocationControl';

export type DynamicFormControlProps<V extends BaseValues> = {
  field: FormFieldConfig<V>;
  values: V;
  errors: FormikErrors<V>;
  value?: any;
  error?: any;
  onChange: (value: any) => void;
  onBlur: () => void;
};

export const DynamicFormControl = <V extends BaseValues>({
  field,
  values,
  errors,
  ...props
}: DynamicFormControlProps<V>) => {
  switch (field.type) {
    case 'textField':
      return <TextFormControl field={field as TextFieldConfig} {...props} />;
    case 'upload':
      return <UploaderFormControl field={field as UploadFormFieldConfig} {...props} />;
    case 'select':
      return (
        <SelectFormControl
          {...props}
          field={field as SelectFormFieldConfig}
          connectedFieldValue={
            field?.connectedField && values[field?.connectedField] ? values[field?.connectedField] : undefined
          }
        />
      );
    case 'carPartAllocation':
      return <CarPartAllocationControl {...props} field={field as CarPartAllocationConfig} />;
  }
  return null;
};
