import React, { useState } from 'react';
import type { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useStore } from 'store';
import { Api } from 'utils/Api';
import { MEDIA_FILE_URL } from 'store/api';
import defaultImage from 'assets/png/default-user.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

import { FieldComponentProps } from './types';
import { UploadFormFieldConfig } from '../config';
import { Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { formatBytes } from '../../../utils/helpers';
import { env } from 'store/env';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    files: {
      backgroundColor: theme.palette.background.default,
      marginTop: 10,
    },
    label: {
      position: 'relative',
      top: -15,
    },
    uploader: {
      border: '1px solid rgba(0, 0, 0, 0.42)',
      borderRadius: 4,
      width: 50,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      marginLeft: 20,
    },
    input: {
      display: 'none',
    },
  })
);

export const UploaderFormControl: React.FC<FieldComponentProps<UploadFormFieldConfig>> = ({
  field,
  value,
  onChange,
}) => {
  const classes = useStyles();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const {
    state: { user },
  } = useStore();

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsBusy(true);

    const helper = async (file: File): Promise<void> => {
      const formData: FormData = new FormData();
      formData.append('userId', user?.sub || '');
      formData.append('files', file);

      const res = await Api.post(MEDIA_FILE_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setIsBusy(false);
      onChange(field?.multiple ? [res?.data?.[0], ...(value ?? [])] : res?.data?.[0]);
    };

    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        helper(e.target.files[i]);
      }
    }
  };

  const onDeleteFile = (file: any) => {
    onChange(field?.multiple ? (value ?? []).filter((f: any) => f?.location !== file?.location) : null);
  };

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="component-simple" className={classes.label}>
        {field.label}
      </InputLabel>
      <input
        accept={field.fileType === 'image' ? 'image/*' : 'application/pdf'}
        className={classes.input}
        id={`upload-input-${field.name}`}
        type="file"
        onChange={onChangeFile}
        multiple={field.multiple}
      />
      <div className={classes.container}>
        <label htmlFor={`upload-input-${field.name}`} className={classes.uploader}>
          {isBusy ? (
            <CircularProgress />
          ) : (
            <IconButton color="primary" aria-label="upload picture" component="span">
              {field.fileType === 'image' ? <PhotoCamera /> : <PictureAsPdf />}
            </IconButton>
          )}
        </label>
        <List dense className={classes.files}>
          {field.multiple &&
            value?.map((item: any, key: number) => {
              return (
                <ListItem key={key}>
                  <ListItemAvatar>
                    <Avatar
                      variant={'rounded'}
                      src={
                        field.fileType === 'image'
                          ? `${env.REACT_APP_BACKEND_IMAGES_PATH}${item?.location || item || defaultImage}`
                          : undefined
                      }>
                      <PictureAsPdf />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item?.fileName} secondary={formatBytes(item?.fileSize ?? 0)} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onDeleteFile(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          {!field.multiple && value && (
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  variant={'rounded'}
                  src={
                    field.fileType === 'image'
                      ? `${env.REACT_APP_BACKEND_IMAGES_PATH}${value?.location || value || defaultImage}`
                      : undefined
                  }>
                  <PictureAsPdf />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={value?.fileName} secondary={formatBytes(value?.fileSize ?? 0)} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => onDeleteFile(value)} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </div>
    </FormControl>
  );
};
