import React, { useState, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import { Search } from '../../../components/Search';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { buildDictionaryListColumns } from './columns';
import { DICTIONARY_URL } from '../../../store/api';
import { ListTable } from '../../../components/ListTable';
import { ListDataProvider, useListDataProvider } from '../../../hooks/useListDataProvider';
import { Api } from '../../../utils/Api';
import { OrganizationSelect } from '../../../components/OrganizationSelect';
import { IDictionary } from '../../../types/dictionaries';
import { useStore } from '../../../store';
import { RolesTypeEnum } from '../../../utils/filterColumns';
import { EmptyPlaceholder } from 'components/EmptyPlaceholder';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'none',
      paddingBottom: 20,
    },
    paper: {
      background: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    button: {
      color: '#1554d5',
      marginTop: 16,
    },
  })
);

const dictionaryDataProvider: ListDataProvider<IDictionary> = async ({ offset, limit, organizationId }) => {
  const response = await Api.get<{ data: IDictionary[]; total: number }>(DICTIONARY_URL, {
    offset,
    limit,
    organizationId,
  });
  return {
    total: response.data.total,
    data: response.data.data.map(({ id, ...rest }) => ({
      ...rest,
      id: id ?? rest.type,
    })),
  };
};

export const DictionaryList: React.FC = () => {
  const {
    state: { user },
  } = useStore();
  const [searchValue, setSearchValue] = useState<string>('');
  const classes = useStyles();
  const listData = useListDataProvider(dictionaryDataProvider, {
    limit: 10,
  });

  const columns = useMemo(() => buildDictionaryListColumns(), []);

  return (
    <div>
      <Paper elevation={0} className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <Search
            placeholder="Search by name or code"
            onChange={(value) => {
              setSearchValue(value);
              listData.onSearch(value);
            }}
          />
          {user?.role === RolesTypeEnum.ADMIN && (
            <OrganizationSelect
              // organizationId={listData.query.organizationId}
              onChange={listData.onChangeOrganization}
            />
          )}
        </Paper>
      </Paper>
      {searchValue !== '' && listData.total === 0 ? (
        <EmptyPlaceholder />
      ) : (
        <ListTable
          columns={columns}
          loading={listData.loading}
          data={listData.data}
          total={listData.total}
          offset={listData.query.offset}
          limit={listData.query.limit}
          onPageChange={listData.onPageChange}
          onFilterChange={listData.onFilter}
        />
      )}
    </div>
  );
};
