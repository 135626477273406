import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { TASKS_URL } from 'store/api';

import { ListSection } from 'sections/ListSection';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { TASK_FORM_CONFIG } from './task-form.config';
import { TASKS_DETAILS_CONFIG } from './task-details.config';

export const Tasks = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <ListSection placeholder="Search by name or code" url={TASKS_URL} columnName="tasks" />;
  } else if (content === PageContenEnum.SHOW) {
    return <ShowSection url={TASKS_URL} config={TASKS_DETAILS_CONFIG} id={id} title="Task Details" />;
  } else if (content === PageContenEnum.EDIT) {
    return <EditSection id={id} url={TASKS_URL} title="Edit Task" config={TASK_FORM_CONFIG} dataType="task" />;
  }

  return <AddSection title="Create Task" config={TASK_FORM_CONFIG} dataType="task" />;
};
