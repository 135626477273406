import { createRestDataSource } from 'components/DynamicForm';
import { CAR_URL } from 'store/api';
import { dateTimeString } from 'components/DetailsView/config/helpers';

export const CAR_DETAILS_CONFIG = {
  dataSource: createRestDataSource(CAR_URL),
  mapSourceToValues: (data: any) => ({
    name: data?.name,
    color: data?.color,
    racerNumber: data?.racerNumber,
    driver: data?.driver,
    sponsor: data?.sponsor,
    id: data?.id,
    code: data?.code,
    createdAt: dateTimeString(data?.createdAt).getDate(),
    updatedAt: dateTimeString(data?.updatedAt).getDate(),
    registrationNumber: data?.registrationNumber,
    dateFirstRegistration: dateTimeString(data?.dateFirstRegistration).getDate(),
    dateRegistration: dateTimeString(data?.dateRegistration).getDate(),
    ownerName: data?.ownerName,
    ownerAddress: data?.ownerAddress,
    yearManufacturer: data?.yearManufacturer,
    vinNumber: data?.vinNumber,
    mark: data?.mark?.name,
    model: data?.model?.name,
    bodyType: data?.bodyType,
    maxMass: data?.maxMass,
    massInService: data?.massInService,
    capacity: data?.capacity,
    fuel: data?.fuel,
  }),
  fields: [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'color',
      label: 'Color',
    },
    {
      name: 'racerNumber',
      label: 'Racer#',
    },
    {
      name: 'driver',
      label: 'Driver',
    },
    {
      name: 'sponsor',
      label: 'Main sponsor',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'createdAt',
      label: 'Created',
    },
    {
      name: 'registrationNumber',
      label: 'Registration number',
    },
    {
      name: 'dateFirstRegistration',
      label: 'Date of first registration',
    },
    {
      name: 'dateRegistration',
      label: 'Date of registration',
    },
    {
      name: 'ownerName',
      label: 'Owner name',
    },
    {
      name: 'ownerAddress',
      label: 'Owner address',
    },
    {
      name: 'yearManufacturer',
      label: 'Year of manufacture',
    },
    {
      name: 'vinNumber',
      label: 'Vin',
    },
    {
      name: 'mark',
      label: 'Mark',
    },
    {
      name: 'model',
      label: 'Model',
    },
    {
      name: 'bodyType',
      label: 'Body type',
    },
    {
      name: 'maxMass',
      label: 'Maximum mass',
    },
    {
      name: 'massInService',
      label: 'Mass in servicess',
    },
    {
      name: 'capacity',
      label: 'Capacity',
    },
    {
      name: 'fuel',
      label: 'Type of fuel',
    },
  ],
};
