import { Mosaic, MosaicHttp, MosaicInfo, NamespaceHttp, NamespaceId } from 'symbol-sdk';

import { IMosaicModel } from '../../types/models/MosaicModel';
import { INetworkModel } from '../../types/models/NetworkModel';

export default class MosaicService {
  /**
   * Gets MosaicModel from a Mosaic
   * @param mosaic
   * @param network
   * @return {Promise<{amount: string, mosaicId: string, mosaicName: *, divisibility: *}>}
   */
  public static async getMosaicModelFromMosaicId(mosaic: Mosaic, network: INetworkModel): Promise<IMosaicModel> {
    let mosaicInfo: any = {},
      mosaicName: any = {},
      amount: any = mosaic.amount;
    const mosaicId: any = mosaic.id;
    try {
      const info: any = await new MosaicHttp(network.node).getMosaic(mosaicId).toPromise();
      mosaicInfo = info;
      const namespaceHttp: any = await new NamespaceHttp(network.node).getMosaicsNames([mosaicId]).toPromise();
      [mosaicName] = namespaceHttp;
    } catch (e) {
      console.error(e, 26);
    }
    //Mosaic info not found -> let's try for its namespace
    if (!mosaicInfo.divisibility) {
      try {
        const namespaceInfo: any = await new NamespaceHttp(network.node)
          .getNamespace(mosaic.id as unknown as NamespaceId)
          .toPromise();
        if (namespaceInfo.alias.mosaicId) {
          const info: any = await new MosaicHttp(network.node).getMosaic(namespaceInfo.alias.mosaicId).toPromise();
          mosaicInfo = info;
          const namespaceHttp: any = await new NamespaceHttp(network.node)
            .getMosaicsNames([namespaceInfo.alias.mosaicId])
            .toPromise();
          [mosaicName] = namespaceHttp;
        }
      } catch (e) {
        console.error(e);
      }
    }
    amount = mosaic.amount.toString();
    return {
      mosaicId: mosaic.id.toHex(),
      mosaicName:
        mosaicName && mosaicName.names && mosaicName.names.length > 0 ? mosaicName.names[0].name : mosaic.id.toHex(),
      amount,
      divisibility: mosaicInfo.divisibility,
      expired: mosaicInfo.duration ? this._checkExpirationDate(mosaicInfo, network) : false,
    };
  }

  /**
   * Checks expiration date of a mosaic
   * @param mosaicInfo
   * @param network
   * @returns {string|boolean}
   * @private
   */
  private static _checkExpirationDate(mosaicInfo: MosaicInfo, network: INetworkModel): boolean {
    const duration = mosaicInfo.duration.compact();
    const startHeight = mosaicInfo.startHeight.compact();

    // unlimited duration mosaics are flagged as duration == 0
    if (duration === 0) {
      return false;
    }

    // get current height
    const expiresIn = startHeight + duration - (network.chainHeight || 0);
    return expiresIn <= 0;
  }
}
