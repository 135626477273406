import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';
import { useStore } from '../store';

type ListData<T> = {
  data: T[];
  total: number;
};
export type ListDataSortQuery = {
  field: string;
  sort: 'ASC' | 'DESC';
};
export type ListDataQuery = {
  limit: number;
  offset: number;
  search: string;
  filter: Record<string, string>;
  sort?: ListDataSortQuery | null;
  organizationId?: string;
  id?: string;
};

export type ListDataProvider<T> = (query: ListDataQuery) => Promise<ListData<T>>;

export const useListDataProvider = <T>(dataProvider: ListDataProvider<T>, defaultQuery: Partial<ListDataQuery>) => {
  const {
    state: { user },
  } = useStore();

  const [query, setQuery] = useState<ListDataQuery>({
    offset: 0,
    limit: 10,
    filter: {},
    search: '',
    organizationId: user?.organizationId,
    ...defaultQuery,
  });

  useEffect(() => {
    setQuery((query) => ({
      ...query,
      organizationId: user?.organizationId,
    }));
  }, [user?.organizationId]);

  const [reloadTime, setReloadTime] = useState<number>(Date.now());
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDebounce(searchValue, 500);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setQuery((query) => ({
      ...query,
      offset: 0,
      search: debouncedValue,
    }));
  }, [debouncedValue]);

  useEffect(() => {
    setLoading(true);
    dataProvider(query)
      .then(({ data, total }) => {
        setTotal(total);
        setData(data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [query, dataProvider, reloadTime]);

  const onSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const onFilter = useCallback((filter: Record<string, string>) => {
    setQuery((query) => ({
      ...query,
      offset: 0,
      filter,
    }));
  }, []);

  const onChangeOrganization = useCallback((organizationId: string) => {
    setQuery((query) => ({
      ...query,
      offset: 0,
      filter: {},
      search: '',
      organizationId,
    }));
  }, []);

  const onSort = useCallback((sortQuery: ListDataSortQuery | null) => {
    setQuery((query) => ({
      ...query,
      offset: 0,
      sort: sortQuery,
    }));
  }, []);

  const onClearFilter = useCallback((name: string) => {
    setQuery((query) => ({
      ...query,
      offset: 0,
      filter: {
        ...query.filter,
        [name]: '',
      },
    }));
  }, []);

  const onPageChange = useCallback((offset: number, limit: number) => {
    setQuery((query) => ({
      ...query,
      offset,
      limit,
    }));
  }, []);

  const reload = useCallback(() => {
    setReloadTime(Date.now());
  }, []);

  return {
    total,
    data,
    error,
    query,
    loading,
    onSort,
    reload,
    onSearch,
    onFilter,
    onClearFilter,
    onPageChange,
    onChangeOrganization,
  };
};
