import { createRestDataSource, dicToOptionMapper, FormConfig } from 'components/DynamicForm';
import { TASKS_TEMPLATE_URL } from 'store/api';
import * as Yup from 'yup';
import { RootState } from '../../store/state';

export type TaskTemplateFormValues = {
  typeId: string;
  taskName: string;
  assigneeId: string;
  description: string;
  organizationId?: string;
};

export const TASK_TEMPALTE_FORM_CONFIG: FormConfig<any, TaskTemplateFormValues> = {
  dataSource: createRestDataSource(TASKS_TEMPLATE_URL),
  mapSourceToValues: (data) => ({
    typeId: data.taskType?.id,
    taskName: data.taskName,
    assigneeId: data.assignee?.id,
    description: data.description,
  }),
  defaultValues: {
    typeId: '',
    taskName: '',
    assigneeId: '',
    description: '',
  },
  fields: [
    {
      name: 'typeId',
      type: 'select',
      label: 'Type',
      required: true,
      optionsSelector: ({ taskTypeDictionary }) =>
        taskTypeDictionary.map((item: any) => dicToOptionMapper(item, false)),
    },
    {
      name: 'taskName',
      label: 'Task Name',
      type: 'textField',
      required: true,
      validation: Yup.string()
        .required('Please enter the name of the task name.')
        .max(100, 'The name must be less than 100 characters'),
    },
    {
      name: 'assigneeId',
      label: 'Assignee',
      type: 'select',
      optionsSelector: ({ users }) => users.data.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      validation: Yup.string()
        .min(3, 'The description must be longer than or equal to 3 characters')
        .max(4000, 'The description of the task must be less than 4000 characters'),
    },
    {
      name: 'organizationId',
      label: 'Organization',
      type: 'select',
      isDisabled: ({ user }: RootState, mode) => user?.role !== 'admin' || mode === 'edit',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
      validation: Yup.string().uuid().required('Please select Organization.'),
    },
  ],
};
