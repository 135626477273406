import { linkViewCustomUrlFormatter } from '../../../utils/columns';
import { GridColumns } from '@mui/x-data-grid';
import { PageContenEnum } from '../../../utils/contstants';

export const buildDictionaryListColumns = (): GridColumns => [
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 300,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'isEditable',
    headerName: 'Is editable',
    width: 200,
    sortable: false,
    filterable: false,
    type: 'boolean',
  },
  {
    width: 100,
    field: '_view',
    filterable: false,
    sortable: false,
    headerName: 'View',
    renderCell: ({ row }) =>
      row.isEditable ? linkViewCustomUrlFormatter(`dictionaries/${PageContenEnum.SHOW}/${row.id}`) : null,
  },
];
