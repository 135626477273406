import { EVENTS_URL, MAINTENANCE_URL, USER_URL } from 'store/api';
import { ISelectType } from 'components/FilterMenu';

export enum RolesTypeEnum {
  ADMIN = 'admin',
  ORGANIZATION_ADMIN = 'organization_admin',
  ENGINEER = 'engineer',
  MECHANIC = 'mechanic',
}

export const userRoles = [
  { value: 'admin', label: 'admin' },
  { value: 'organization_admin', label: 'organization_admin' },
  { value: 'engineer', label: 'engineer' },
  { value: 'mechanic', label: 'mechanic' },
];

type IColumnType = {
  title: string;
  filterKey: string;
  url?: string;
  customData?: ISelectType[];
  dictionaryName?: string;
};

type IFilterColumnsType = {
  [key: string]: IColumnType[];
};

export const filterColumns: IFilterColumnsType = {
  maintenance: [
    { title: 'Events', filterKey: 'filter[event][id]', url: EVENTS_URL },
    { title: 'Status', filterKey: 'filter[status][id]', dictionaryName: 'maintenanceStatusDictionary' },
  ],
  cars: [],
  tasks: [
    { title: 'Events', filterKey: 'filter[maintenance][event][id]', url: EVENTS_URL },
    { title: 'Maintenance', filterKey: 'filter[maintenance][id]', url: MAINTENANCE_URL },
    { title: 'Type', filterKey: 'filter[type][id]', dictionaryName: 'taskTypeDictionary' },
    { title: 'Assignee', filterKey: 'filter[assignee][id]', url: USER_URL },
    { title: 'Status', filterKey: 'filter[status][id]', dictionaryName: 'taskStatusDictionary' },
  ],
  events: [
    { title: 'Type', filterKey: 'filter[type][id]', dictionaryName: 'eventTypeDictionary' },
    { title: 'Championship', filterKey: 'filter[championship][id]', dictionaryName: 'eventChampionshipDictionary' },
    { title: 'Status', filterKey: 'filter[status][id]', dictionaryName: 'eventStatusDictionary' },
  ],
  'car-parts': [
    { title: 'Status', filterKey: 'filter[status][id]', dictionaryName: 'carPartStatusDictionary' },
    { title: 'Serial number', filterKey: 'filter[serial]', dictionaryName: 'carPartStatusDictionary' },
  ],
  users: [{ title: 'Role', filterKey: 'filter[role]', customData: userRoles }],
  organizations: [],
  dictionaries: [],
  'task-templates': [],
};
