import { createRestDataSource, FormConfig } from 'components/DynamicForm';
import { ORGANIZATION_URL } from 'store/api';
import { IFileType } from 'types/common';
import { IOrganization } from 'types/organizations';
import * as Yup from 'yup';

export type OrganizationFormValues = {
  name: string;
  logo: IFileType | null;
};

export const ORGANIZATION_FORM_CONFIG: FormConfig<IOrganization, OrganizationFormValues> = {
  dataSource: createRestDataSource(ORGANIZATION_URL),
  mapSourceToValues: (data) => ({
    name: data.name,
    logo: data.logo,
  }),
  defaultValues: {
    name: '',
    logo: null,
  },
  fields: [
    {
      name: 'logo',
      label: 'Logo',
      type: 'upload',
      fileType: 'image',
    },
    {
      name: 'name',
      label: 'Name',
      type: 'textField',
      required: true,
      validation: Yup.string()
        .required('Please enter the name of the task name.')
        .max(100, 'The name must be less than 100 characters'),
    },
  ],
};
