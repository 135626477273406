import { createRestDataSource } from 'components/DynamicForm';
import { CAR_PART_URL } from 'store/api';
import { dateTimeString } from 'components/DetailsView/config/helpers';

export const CAR_PART_DETAILS_CONFIG = {
  dataSource: createRestDataSource(CAR_PART_URL),
  mapSourceToValues: (data: any) => {
    return {
      status: data?.status?.name,
      mark: data?.model?.mark?.name,
      model: data?.model?.name,
      name: data?.name,
      serial: data?.serial,
      category: data?.category?.parent?.name,
      subCategory: data?.category?.name,
      number: data?.number,
      quantity: data?.quantity,
      price: data?.price,
      id: data?.id,
      code: data?.code,
      createdAt: dateTimeString(data?.createdAt).getDate(),
      updatedAt: dateTimeString(data?.updatedAt).getDate(),
      mileageCurrentTime: data?.mileageCurrentTime,
      mileageMaxTime: data?.mileageMaxTime,
      mileageCurrentDistance: data?.mileageCurrentDistance,
      mileageMaxDistance: data?.mileageMaxDistance,
      manufactureDate: dateTimeString(data?.manufactureDate).getDate(),
      manufactureExpirationDate: dateTimeString(data?.manufactureExpirationDate).getDate(),
    };
  },
  fields: [
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'mark',
      label: 'Mark',
    },
    {
      name: 'model',
      label: 'Model',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'serial',
      label: 'Serial number',
    },
    {
      name: 'category',
      label: 'Category name',
    },
    {
      name: 'subCategory',
      label: 'Sub-Category',
    },
    {
      name: 'number',
      label: 'Part number',
    },
    {
      name: 'quantity',
      label: 'QTY',
    },
    {
      name: 'price',
      label: 'price per part',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'createdAt',
      label: 'Created',
    },
    {
      name: 'updatedAt',
      label: 'Updated',
    },
    {
      name: 'mileageCurrentTime',
      label: 'Current time',
    },
    {
      name: 'mileageMaxTime',
      label: 'Maximum time',
    },
    {
      name: 'mileageCurrentDistance',
      label: 'Current distance',
    },
    {
      name: 'mileageMaxDistance',
      label: 'Maximum distance',
    },
    {
      name: 'manufactureExpirationDate',
      label: 'Manufacture expiration date',
    },
    {
      name: 'manufactureDate',
      label: 'Manufacture date',
    },
  ],
};
