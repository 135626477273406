import { ShowHeader } from 'components/ShowHeader';
import { DetailsView } from 'components/DetailsView';
import { DictionaryDetailsView } from 'components/DictionaryDetailsView';
import { ListConfig } from 'components/DetailsView/config/types';

import { Grid } from '@material-ui/core';

type IShowSection = {
  url?: string;
  title: string;
  config?: ListConfig<any, any>;
  id?: string;
  isDictionary?: boolean;
  columnName?: string;
};

export const ShowSection = ({ title, config, id, isDictionary = false, columnName }: IShowSection) => {
  return (
    <div>
      <ShowHeader title={title} />
      {config && id ? (
        <Grid container justifyContent="center">
          <Grid xs={6}>
            {isDictionary ? (
              <DictionaryDetailsView id={id} config={config} columnName={columnName} />
            ) : (
              <DetailsView id={id} config={config} />
            )}
          </Grid>
        </Grid>
      ) : (
        'No config or id'
      )}
    </div>
  );
};
