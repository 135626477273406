import React, { Component, ErrorInfo, ReactNode } from 'react';

import 'components/ErrorBoundary/styles.css';

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="error-wrapper">
          <p className="error-paragraph">Sorry.. there was an error</p>
        </div>
      );
    }

    return this.props.children;
  }
}
