import { Overmind } from 'overmind';
import jwt_decode from 'jwt-decode';
import { Context, OvermindContext } from 'store';
import { Api } from 'utils/Api';
import { getRouteByRole, defaultRoute } from 'utils/routes';
import { appKey } from 'utils/contstants';
import { createParams, showError } from 'utils/helpers';
import { RolesTypeEnum } from 'utils/filterColumns';
import { List } from 'types/list';
import { EventDetails } from 'types/events';
import {
  ORGANIZATION_URL,
  MAINTENANCE_URL,
  AUTH_SIGNIN_URL,
  DICTIONARY_URL,
  CAR_MODEL_URL,
  CAR_MARK_URL,
  LOGOUT_URL,
  EVENTS_URL,
  USER_URL,
  CAR_URL,
} from 'store/api';

import { ISignInFormValues } from 'types/sing-in';
import { IToken_Obj } from 'types/users';
import { LOCAL_STORAGE_JWT_TOKEN_KEY, STATE_PERSIST_KEYS } from '../contstants';
import { RootState } from '../state';
import { AxiosResponse } from 'axios';
import TransactionService from 'services/symbol/TransactionService';
import AccountService from 'services/symbol/AccountService';
import { Account, NetworkType, PublicAccount } from 'symbol-sdk';

const persist = (key: string, data: any) => {
  if (STATE_PERSIST_KEYS.includes(key as keyof RootState)) {
    const persistKey = `${appKey}-${key}`;
    localStorage.setItem(persistKey, JSON.stringify(data));
  }
};

const getPersisted = (key: string) => {
  const data = localStorage.getItem(`${appKey}-${key}`);
  return data && JSON.parse(data);
};

export const onInitialize = async ({ state }: Context, overmind: Overmind<OvermindContext>) => {
  overmind.addMutationListener(({ path, args: [data] }) => {
    persist(path, data);
  });

  STATE_PERSIST_KEYS.forEach((key) => {
    const value = getPersisted(key);
    value && (state[key] = value);
  });
};

export const login = async ({ state }: Context, values: ISignInFormValues) => {
  try {
    state.isFetching = true;
    const { rememberMe } = values;
    const newValues = {
      username: values.username.trim(),
      password: values.password.trim(),
    };
    const response: any = await Api.post(AUTH_SIGNIN_URL, newValues);
    const token_obj: IToken_Obj = response.data;
    localStorage.setItem(LOCAL_STORAGE_JWT_TOKEN_KEY, JSON.stringify(token_obj));
    state.auth = token_obj;
    state.isFetching = false;
    const decoded: { sub: string; role: string } = jwt_decode(token_obj.accessToken);

    const wallet: AxiosResponse<any> = await Api.get(`wallet/user/${decoded.sub}`);
    localStorage.setItem('wallet', JSON.stringify(wallet.data));
    if (rememberMe) {
      localStorage.setItem('rememberMe', JSON.stringify(newValues));
    }

    if (decoded.role === RolesTypeEnum.ORGANIZATION_ADMIN) {
      window.location.pathname = getRouteByRole(RolesTypeEnum.ORGANIZATION_ADMIN).path;
    } else {
      window.location.pathname = defaultRoute.path;
    }
  } catch (err: any) {
    state.isFetching = false;
    showError(err?.response?.data?.message);
  }
};

export const logout = async () => {
  try {
    await Api.delete(LOGOUT_URL);
    flushAccessToken();
  } catch {
    flushAccessToken();
  }
};

export const flushAccessToken = () => {
  localStorage.clear();
  window.location.pathname = '/';
};

export const getAccount = ({ state }: Context) => {
  const accessToken: any = JSON.parse(localStorage.getItem(LOCAL_STORAGE_JWT_TOKEN_KEY) || '{}')?.accessToken;
  if (!accessToken) throw Error('Access token not found');

  const user: any = jwt_decode(accessToken);
  state.user = user;
};

export const getDictionaries = async ({ state }: Context) => {
  try {
    const res = await Api.get(DICTIONARY_URL + createParams({ offset: 0, limit: 100 }));
    state.dictionary = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const handleLoading = ({ state }: Context, isFetching: boolean) => {
  state.isFetching = isFetching;
};

export const getEvents = async ({ state }: Context) => {
  try {
    const res = await Api.get(EVENTS_URL + createParams({ offset: 0, limit: 1000 }));
    state.events = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const changeEventsList = ({ state }: Context, values: List<EventDetails>) => {
  state.events = values;
};

export const getCars = async ({ state }: Context) => {
  try {
    const res = await Api.get(CAR_URL + createParams({ offset: 0, limit: 100 }));
    state.cars = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const getMaintenances = async ({ state }: Context) => {
  try {
    const res = await Api.get(MAINTENANCE_URL + createParams({ offset: 0, limit: 100 }));
    state.maintenances = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const getUsers = async ({ state }: Context) => {
  try {
    const res = await Api.get(USER_URL + createParams({ offset: 0, limit: 100 }));
    state.users = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const getOrganizations = async ({ state }: Context) => {
  try {
    const res = await Api.get(ORGANIZATION_URL + createParams({ offset: 0, limit: 100 }));
    state.organizations = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const getMarks = async ({ state }: Context) => {
  try {
    const res = await Api.get(CAR_MARK_URL + createParams({ offset: 0, limit: 100 }));
    state.carMarks = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const getModels = async ({ state }: Context) => {
  try {
    const res = await Api.get(CAR_MODEL_URL + createParams({ offset: 0, limit: 1000 }));
    state.carModels = res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      logout();
    }
  }
};

export const createCarWallet = async (payload: any) => {
  try {
    const { account, carData, network, userWallet } = payload;
    const hashSum: string = TransactionService.generateHush(carData, 'car');
    const userAddress: string = AccountService.getAddressFromPrivateKey(userWallet.privateKey, network.type);
    const { ownedMosaics, balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(userAddress, network);
    const { address }: any = PublicAccount.createFromPublicKey(
      account.publicKey,
      network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
    );
    if (balance > 0) {
      const userAccount = Account.createFromPrivateKey(
        userWallet.privateKey,
        network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
      );
      const path = `m/44'/${network.type === 'testnet' ? '1' : '4343'}'/0'/0'/0'`;

      const selectedAccount = AccountService.symbolAccountToAccountModel(userAccount, 'wallet', 'hd', path);
      TransactionService.makeTransaction(address.address, hashSum, ownedMosaics, network, selectedAccount);
    }
  } catch (e: any) {
    console.error(e);
  }
};
