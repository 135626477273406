import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface IEmptyPlaceholderProps {
  text?: string;
}

const useStyles = makeStyles({
  placeholderWrap: {
    margin: '40px 0 40px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  placeholderText: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
});

export function EmptyPlaceholder({ text = 'Sorry, no matches found!' }: IEmptyPlaceholderProps) {
  const classes = useStyles();

  return (
    <div className={classes.placeholderWrap}>
      <span className={classes.placeholderText}>{text}</span>
    </div>
  );
}
