import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Paper, TextField } from '@material-ui/core';
import { ShowHeader } from '../../../components/ShowHeader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Api } from '../../../utils/Api';
import { DICTIONARY_VALUE_URL } from '../../../store/api';
import { useHistory } from 'react-router-dom';

export type DictionaryFormProps = {
  dictionaryId: string;
  mode: 'create' | 'edit';
};

type DictionaryValues = {
  name: string;
  nameFr?: string;
  alias: string;
  color?: string;
  backgroundColor?: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  alias: Yup.string().required(),
  nameFr: Yup.string().nullable(),
  color: Yup.string().nullable(),
  backgroundColor: Yup.string().nullable(),
});

export const DictionaryForm: React.FC<DictionaryFormProps> = ({ dictionaryId, mode }) => {
  const { goBack } = useHistory();

  const formik = useFormik<DictionaryValues>({
    validationSchema,
    initialValues: {
      name: '',
      alias: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, formikHelpers) => {
      try {
        console.log(values);
        if (mode === 'create') {
          await Api.post(DICTIONARY_VALUE_URL, {
            ...values,
            dictionaryId,
          });
        } else {
          await Api.put(`${DICTIONARY_VALUE_URL}/${dictionaryId}`, {
            ...values,
          });
        }
        goBack();
      } catch (e: any) {
        alert(`Something went wrong ${e?.message}`);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (mode === 'edit') {
      Api.get(`${DICTIONARY_VALUE_URL}/${dictionaryId}`).then(({ data }) => {
        console.log(data);
        formik.setFormikState((state) => ({
          ...state,
          values: {
            name: data.name,
            nameFr: data.nameFr,
            alias: data.alias,
            color: data.color,
            backgroundColor: data.backgroundColor,
          },
        }));
      });
    }
  }, [mode]);

  return (
    <Container component="main" maxWidth={'sm'}>
      <ShowHeader title={mode === 'create' ? 'Create dictionary' : 'Edit Dictionary'} />
      <Paper variant="elevation">
        <Box p={5} pb={6} pt={6}>
          <Grid container spacing={5}>
            <Grid xs={12} sm={12} md={12} lg={12} item>
              <TextField
                value={formik.values.name}
                type="textField"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={true}
                name={'name'}
                label={'Name'}
                fullWidth
                variant="standard"
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} item>
              <TextField
                value={formik.values.nameFr}
                type="textField"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={'nameFr'}
                label={'Name (French)'}
                fullWidth
                variant="standard"
                error={!!formik.errors.nameFr}
                helperText={formik.errors.nameFr}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} item>
              <TextField
                value={formik.values.alias}
                type="textField"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={true}
                name={'alias'}
                label={'Alias'}
                fullWidth
                variant="standard"
                error={!!formik.errors.alias}
                helperText={formik.errors.alias}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} item>
              <TextField
                value={formik.values.color}
                type="textField"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={'color'}
                label={'Color'}
                fullWidth
                variant="standard"
                error={!!formik.errors.color}
                helperText={formik.errors.color}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} item>
              <TextField
                value={formik.values.backgroundColor}
                type="textField"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={'backgroundColor'}
                label={'Background color'}
                fullWidth
                variant="standard"
                error={!!formik.errors.backgroundColor}
                helperText={formik.errors.backgroundColor}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} justifyContent="flex-end">
            <Grid item>
              <Button
                disabled={formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
                variant="contained"
                color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};
