import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { FormControl, FormHelperText, InputLabel, NativeSelect } from '@material-ui/core';
import { FieldComponentProps } from './types';
import { SelectFieldOption, SelectFormFieldConfig } from '../config';

export const SelectFormControl: React.FC<FieldComponentProps<SelectFormFieldConfig>> = ({
  field,
  value,
  connectedFieldValue,
  onChange,
  onBlur,
  error,
}) => {
  const { state } = useStore();
  const options =
    (field?.optionsSelector && field?.optionsSelector(state, connectedFieldValue)) || field?.options || [];

  const [lazyOptions, setLazyOptions] = useState<SelectFieldOption[]>([]);
  useEffect(() => {
    if (field.getOptions) {
      field.getOptions(connectedFieldValue).then(setLazyOptions);
    }
  }, [field, connectedFieldValue]);

  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} variant="standard" required={field.required} htmlFor={field.name as string}>
        {field.label}
      </InputLabel>
      <NativeSelect
        error={!!error}
        inputProps={{
          id: field.name as string,
        }}
        variant="standard"
        value={value}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        fullWidth>
        <option disabled selected={!value} />
        {options.concat(lazyOptions).map(({ value: currentValue, label }) => (
          <option selected={value === currentValue} key={currentValue} value={currentValue}>
            {label}
          </option>
        ))}
      </NativeSelect>
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};
