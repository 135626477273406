import { createRestDataSource } from 'components/DynamicForm';
import { ORGANIZATION_URL } from 'store/api';
import { dateTimeString } from 'components/DetailsView/config/helpers';
import { IOrganization } from '../../types/organizations';
import { ListConfig } from '../../components/DetailsView/config';

export const ORGANIZATION_DETAILS_CONFIG: ListConfig<IOrganization, any> = {
  dataSource: createRestDataSource(ORGANIZATION_URL),
  mapSourceToValues: (data) => {
    return {
      name: data.name,
      logo: data?.logo?.location,
      createdAt: dateTimeString(data?.createdAt).getDate(),
      updatedAt: dateTimeString(data?.updatedAt).getDate(),
    };
  },
  fields: [
    {
      name: 'logo',
      label: 'Logo',
      type: 'image',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'createdAt',
      label: 'Created',
    },
    {
      name: 'updatedAt',
      label: 'Updated',
    },
  ],
};
