import { DataSource, dicToOptionMapper, formatIsoString, FormConfig } from 'components/DynamicForm';
import { ICar, TCarModel } from 'types/cars';
import { CAR_MODEL_URL, CAR_URL, GET_WALLET_URL } from 'store/api';
import * as Yup from 'yup';
import { Api } from '../../utils/Api';
import { createParams } from '../../utils/helpers';
import { List } from '../../types/list';
import { TFile } from '../../types/TFile';
import NetworkService from '../../services/symbol/NetworkService';
import TransactionService from '../../services/symbol/TransactionService';
import AccountService from '../../services/symbol/AccountService';
import { Account, NetworkType, PublicAccount } from 'symbol-sdk';
import { RootState } from '../../store/state';

export type CarFormValues = {
  name: string;
  vinNumber?: string;
  markId?: any;
  modelId?: any;
  color?: string;
  racerNumber?: string;
  ownerName?: string;
  driver?: string;
  sponsor?: string;
  capacity?: number;
  bodyType?: string;
  fuel?: string;
  maxMass?: number;
  ownerAddress?: string;
  massInService?: number;
  mileageCurrentTime?: number;
  mileageCurrentDistance?: number;
  yearManufacturer?: number;
  registrationNumber?: string;
  dateRegistration?: string;
  dateFirstRegistration?: string;
  images?: TFile[];
  organizationId?: string;
};

const dataSource: DataSource<ICar, CarFormValues> = {
  get: (id) => Api.get<ICar>(`${CAR_URL}/${id}`).then(({ data }) => data),
  update: async (id, values) => {
    const res = await Api.put(`${CAR_URL}/${id}`, values);
    try {
      const { data: carWallet } = await Api.get(GET_WALLET_URL('car', id));
      const network = await NetworkService.getNetwork();
      const hashSum: string = TransactionService.generateHush({ id, values } as any, 'car');
      const _userWallet: string | null = localStorage.getItem('wallet');
      const userWallet = _userWallet ? JSON.parse(_userWallet) : {};
      const userAddress: string = AccountService.getAddressFromPrivateKey(userWallet.privateKey, network.type);
      const { ownedMosaics, balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(userAddress, network);
      const { address }: any = PublicAccount.createFromPublicKey(
        carWallet.publicKey,
        network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
      );
      if (balance > 0) {
        const userAccount = Account.createFromPrivateKey(
          userWallet.privateKey,
          network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
        );
        const path = `m/44'/${network.type === 'testnet' ? '1' : '4343'}'/0'/0'/0'`;
        const selectedAccount = AccountService.symbolAccountToAccountModel(userAccount, 'wallet', 'hd', path);
        await TransactionService.makeTransaction(address.address, hashSum, ownedMosaics, network, selectedAccount);
      }
    } catch (e) {
      console.error('Blockchain error:', e);
    }
    return res;
  },
  create: async (values) => {
    const { data: carData } = await Api.post(`${CAR_URL}`, values);
    console.log('Create car. Response: ', carData);

    try {
      const { account } = await TransactionService.createWallet();
      const carWallet = {
        entity: 'car',
        entityId: carData.id,
        publicKey: account.publicKey,
        privateKey: account.privateKey,
      };
      await Api.post('/wallet', carWallet);
    } catch (e) {
      console.error('Create car wallet error:', e);
    }
    return { data: carData };
  },
  delete: (id) => Api.delete(`${CAR_URL}/${id}`),
};

export const CAR_FORM_CONFIG: FormConfig<ICar, CarFormValues> = {
  dataSource,
  mapSourceToValues: (data) => ({
    fuel: data.fuel || undefined,
    name: data.name,
    color: data.color,
    markId: data?.mark?.id,
    modelId: data?.model?.id,
    images: data.images,
    driver: data.driver,
    maxMass: data.maxMass || undefined,
    sponsor: data.sponsor,
    bodyType: data.bodyType,
    capacity: data.capacity || undefined,
    vinNumber: data.vinNumber,
    ownerName: data.ownerName,
    racerNumber: data.racerNumber,
    ownerAddress: data.ownerAddress,
    massInService: data.massInService || undefined,
    mileageCurrentTime: Number(data.mileageCurrentTime),
    mileageCurrentDistance: Number(data.mileageCurrentDistance),
    dateRegistration: formatIsoString(data.dateRegistration),
    yearManufacturer: data.yearManufacturer,
    registrationNumber: data.registrationNumber,
    dateFirstRegistration: formatIsoString(data.dateFirstRegistration),
  }),
  defaultValues: {
    name: '',
  },
  fields: [
    {
      name: 'images',
      type: 'upload',
      label: 'Photos',
      fileType: 'image',
      multiple: true,
    },
    {
      name: 'name',
      type: 'textField',
      required: true,
      label: 'Name',
      validation: Yup.string().required('Please enter car name').max(100, 'Invalid value'),
    },
    {
      name: 'color',
      label: 'Color',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'racerNumber',
      label: 'Racer',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'driver',
      label: 'Driver',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'sponsor',
      label: 'Main sponsor',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'mileageCurrentTime',
      label: 'Current Time',
      type: 'textField',
      inputType: 'number',
      validation: Yup.number().max(10000, 'Invalid value'),
    },
    {
      name: 'mileageCurrentDistance',
      label: 'Current Distance',
      type: 'textField',
      inputType: 'number',
      validation: Yup.number().max(10000, 'Invalid value'),
    },
    {
      name: 'registrationNumber',
      label: 'Registration Number',
      type: 'textField',
      inputType: 'text',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'dateFirstRegistration',
      label: 'Date of first registration',
      type: 'textField',
      inputType: 'datetime-local',
    },
    {
      name: 'dateRegistration',
      label: 'Date of registration',
      type: 'textField',
      inputType: 'datetime-local',
    },
    {
      name: 'ownerName',
      label: 'Owner name',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'ownerAddress',
      label: 'Owner address',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'yearManufacturer',
      label: 'Year of manufacture',
      type: 'textField',
    },
    {
      name: 'vinNumber',
      label: 'Vin',
      type: 'textField',
      inputType: 'string',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'markId',
      label: 'Mark',
      required: true,
      type: 'select',
      validation: Yup.string().required('Please select mark'),
      optionsSelector: ({ carMarks }) => carMarks.data.map((item) => dicToOptionMapper(item, false)),
    },
    {
      type: 'select',
      name: 'modelId',
      label: 'Model',
      required: true,
      connectedField: 'markId',
      validation: Yup.string().required('Please select model'),
      getOptions: async (markId) => {
        if (!markId) return Promise.resolve([]);
        const {
          data: { data },
        } = await Api.get<List<TCarModel>>(
          CAR_MODEL_URL +
            createParams({
              offset: 0,
              limit: 1000,
              'filter[mark][id]': markId,
            })
        );
        return data.map(({ id, name }) => ({ value: id, label: name }));
      },
    },
    {
      name: 'bodyType',
      label: 'Body type',
      type: 'textField',
    },
    {
      name: 'maxMass',
      label: 'Maximum mass',
      type: 'textField',
      inputType: 'number',
    },
    {
      name: 'massInService',
      label: 'Mass in service',
      type: 'textField',
      inputType: 'number',
      validation: Yup.number().max(10000, 'Invalid value'),
    },
    {
      name: 'capacity',
      label: 'Capacity',
      type: 'textField',
      inputType: 'number',
      validation: Yup.number().max(10000, 'Invalid value'),
    },
    {
      name: 'fuel',
      label: 'Type of fuel',
      type: 'textField',
      validation: Yup.string().max(100, 'Invalid value'),
    },
    {
      name: 'organizationId',
      label: 'Organization',
      required: true,
      type: 'select',
      isDisabled: ({ user }: RootState, mode) => user?.role !== 'admin' || mode === 'edit',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
      validation: Yup.string().uuid().required('Please select Organization.'),
    },
  ],
};
