import { dicToOptionMapper, FormConfig, SelectFieldOption, DataSource } from 'components/DynamicForm';
import { IUser } from 'types/users';
import { RoleEnum } from 'types/events';
import { USER_URL, GET_WALLET_URL } from 'store/api';
import * as Yup from 'yup';
import { RootState } from '../../store/state';
import { Api } from '../../utils/Api';
import NetworkService from '../../services/symbol/NetworkService';
import TransactionService from '../../services/symbol/TransactionService';
import AccountService from '../../services/symbol/AccountService';
import { Account, NetworkType, PublicAccount } from 'symbol-sdk';

export type UserFormValues = {
  username: string;
  password?: string;
  role: RoleEnum;
  fullName: string;
  organizationId?: string;
  avatar: any;
};

const allRoles: SelectFieldOption[] = ['engineer', 'mechanic', 'admin', 'organization_admin'].map((item) => ({
  label: item,
  value: item,
}));

const organizationUserRoles: SelectFieldOption[] = ['engineer', 'mechanic'].map((item) => ({
  label: item,
  value: item,
}));

const dataSource: DataSource<IUser, UserFormValues> = {
  get: (id) => Api.get<IUser>(`${USER_URL}/${id}`).then(({ data }) => data),
  update: (id, values) => Api.put(`${USER_URL}/${id}`, values),
  create: async (values) => {
    const { data: userData } = await Api.post(`${USER_URL}`, values);
    console.log('Create user. Response: ', userData);

    try {
      const network = await NetworkService.getNetwork();
      const { account } = await TransactionService.createWallet();
      const userWallet = {
        entity: 'user',
        entityId: userData.id,
        publicKey: account.publicKey,
        privateKey: account.privateKey,
      };
      await Api.post('/wallet', userWallet);

      const hashSum: string = TransactionService.generateHush(values, 'user');
      const { data: organizationWallet } = await Api.get(GET_WALLET_URL('organization', userData.organization.id));
      const organizationAddress: string = AccountService.getAddressFromPrivateKey(
        organizationWallet.privateKey,
        network.type
      );
      const { ownedMosaics, balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(
        organizationAddress,
        network
      );

      console.log('ownedMosaics', JSON.stringify(ownedMosaics));
      console.log('balance', balance);

      const { address }: any = PublicAccount.createFromPublicKey(
        userWallet.publicKey,
        network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
      );
      if (balance > 0) {
        const organizationAccount = Account.createFromPrivateKey(
          organizationWallet.privateKey,
          network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
        );
        const path = `m/44'/${network.type === 'testnet' ? '1' : '4343'}'/0'/0'/0'`;
        const selectedAccount = AccountService.symbolAccountToAccountModel(organizationAccount, 'wallet', 'hd', path);
        await TransactionService.makeTransaction(address.address, hashSum, ownedMosaics, network, selectedAccount);
      }
    } catch (e) {
      console.error('Blockchain error:', e);
    }
    return { data: userData };
  },
  delete: (id) => Api.delete(`${USER_URL}/${id}`),
};

export const USER_FORM_CONFIG: FormConfig<IUser, UserFormValues> = {
  dataSource,
  mapSourceToValues: (data) => ({
    username: data.username,
    role: data.role,
    fullName: data.fullName,
    organizationId: data?.organization?.id,
    avatar: data.avatar,
  }),
  defaultValues: {
    username: '',
    role: RoleEnum.engineer,
    fullName: '',
    avatar: '',
  },
  fields: [
    {
      name: 'avatar',
      label: 'Select a photo',
      type: 'upload',
      fileType: 'image',
    },
    {
      name: 'username',
      label: 'UserName',
      type: 'textField',
      required: true,
      validation: Yup.string().required('Please enter the username.'),
    },
    {
      name: 'password',
      label: 'Password',
      type: 'textField',
      getValidation: (mode) => (mode === 'edit' ? Yup.string() : Yup.string().required('Please enter the password')),
    },
    {
      name: 'fullName',
      label: 'Full name',
      type: 'textField',
      required: true,
      validation: Yup.string().required('Please enter the Full name.'),
    },
    {
      name: 'role',
      type: 'select',
      label: 'Role',
      required: true,
      validation: Yup.string().required('Please choose role.'),
      optionsSelector: ({ user }: RootState) => (user?.role === 'admin' ? allRoles : organizationUserRoles),
    },
    {
      name: 'organizationId',
      label: 'Organization',
      type: 'select',
      isDisabled: ({ user }: RootState) => user?.role !== 'admin',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
    },
  ],
};
