import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { EVENTS_URL } from 'store/api';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { EVENT_FORM_CONFIG } from './event-form.config';
import { EVENT_DETAILS_CONFIG } from './event-details.config';
import { EventList } from './EventList/EventList';

export const Events = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <EventList />;
  } else if (content === PageContenEnum.SHOW) {
    return <ShowSection id={id} config={EVENT_DETAILS_CONFIG} title="Event Details" />;
  } else if (content === PageContenEnum.EDIT) {
    return <EditSection id={id} url={EVENTS_URL} title="Edit Event" config={EVENT_FORM_CONFIG} />;
  }
  return <AddSection title="Create Event" config={EVENT_FORM_CONFIG} dataType="event" />;
};
