import { FC, useEffect, useState } from 'react';
import { Box, Paper, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { PageContenEnum } from 'utils/contstants';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListConfig } from '../DetailsView/config/types';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

export interface IDetails {
  id: string;
  config: ListConfig<any, any>;
  columnName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryTypography: {
      '& p': {
        fontSize: '20px',
      },
      '& span': {
        fontSize: '16px',
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export const DictionaryDetailsView: FC<IDetails> = ({ id, config, columnName }) => {
  const [data, satData] = useState<any>([]);
  const classes: any = useStyles();
  console.log('data', data);
  useEffect(() => {
    config.dataSource.get(id).then((data) => {
      satData(config.mapSourceToValues(data));
    });
  }, [config, id]);
  console.log('data ss', data);
  console.log('config.fields', config.fields);
  return (
    <Box>
      <List>
        {config.fields.map((field, index) => {
          return data?.map((d: any) => {
            return (
              <ListItem key={d.id}>
                <ListItemText
                  className={classes.primaryTypography}
                  // primary={field.label}
                  secondary={d[field.name] ?? '-'}
                />
                <Link to={`${columnName}/${PageContenEnum.EDIT}`}>
                  <ListItemIcon>
                    <IconButton edge="end" aria-label="delete">
                      <EditIcon />
                    </IconButton>
                  </ListItemIcon>
                </Link>
              </ListItem>
            );
          });
        })}
      </List>
    </Box>
  );
};
