import { linkViewFormatter } from '../../utils/columns';
import { GridColumns } from '@mui/x-data-grid';
import Avatar from '@material-ui/core/Avatar';
import { env } from 'store/env';

export const buildOrganizationListColumns = (): GridColumns => [
  {
    field: 'logo',
    headerName: 'Logo',
    renderCell: ({ row }) =>
      row?.logo ? (
        <Avatar
          variant="rounded"
          alt="Organization logo"
          src={`${env.REACT_APP_BACKEND_IMAGES_PATH}${row?.logo?.location}`}
        />
      ) : (
        '-'
      ),
    width: 100,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    sortable: true,
    filterable: false,
    type: 'string',
  },
  {
    field: 'countUsers',
    headerName: 'Number of users',
    width: 200,
    sortable: false,
    filterable: false,
    type: 'number',
  },
  {
    width: 100,
    field: '_view',
    filterable: false,
    sortable: false,
    headerName: 'View',
    renderCell: ({ row }) => linkViewFormatter(row.id, 'organizations'),
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    width: 100,
    field: '_edit',
    filterable: false,
    sortable: false,
    headerName: 'Edit',
    renderCell: ({ row }) => linkViewFormatter(row.id, 'organizations', true),
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
  },
];
