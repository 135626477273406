/* eslint-disable no-console */

import { INetworkModel } from '../../types/models/NetworkModel';
import { retryNTimes } from '../../utils/commonHelpers';
import { Address, IListener, Listener, RepositoryFactoryHttp } from 'symbol-sdk';

export default class ListenerService {
  public network!: INetworkModel;
  public repositoryFactory!: RepositoryFactoryHttp;
  public listener!: IListener;
  private _onSuccess?: () => void;
  private _onError?: (err: any) => void;

  public constructor(onSuccess?: () => void, onError?: (e?: Error | string) => void) {
    if (onSuccess) {
      this._onSuccess = onSuccess;
    }
    if (onError) {
      this._onError = onError;
    }
  }

  public onSuccess = (): void => {
    if (this._onSuccess) {
      this._onSuccess();
    }
  };

  public onError = (err?: Error | string): void => {
    if (this._onError) {
      this._onError(err);
    }
  };

  public setNetwork = (network: INetworkModel): void => {
    if (this.listener) {
      this.listener.close();
    }
    this.network = network;

    this.repositoryFactory = new RepositoryFactoryHttp(network.node.replace('https', 'wss'), {
      websocketInjected: WebSocket,
    });
    console.log('---', this.repositoryFactory);
    this.listener = this.repositoryFactory.createListener();
  };

  public listen = (rawAddress: string): Promise<any> => {
    if (this.listener) {
      this.listener.close();
    }

    this.listener = this.repositoryFactory.createListener();
    const address = Address.createFromRawAddress(rawAddress);

    return this.listener
      .open(async (event: { client: string; code: any; reason: any }) => {
        console.log('listener', event);
        if (event && event.code !== 1005) {
          if (this.listener instanceof Listener) {
            await retryNTimes(this.listener, 3, 5000);
          }
        } else {
          this.showMessage('ws_connection_failed', 'danger');
        }
      })
      .then(() => {
        this.addConfirmed(rawAddress);
        this.addUnconfirmed(rawAddress);

        this.listener.aggregateBondedAdded(address).subscribe(() => {
          console.log('success:::LOAD_ALL_DATA');
        });

        this.listener.status(address).subscribe((error: any) => {
          this.showMessage(error.code, 'danger');
        });
      });
  };

  public addConfirmed = (rawAddress: string): void => {
    const address = Address.createFromRawAddress(rawAddress);
    this.listener.confirmed(address).subscribe((data: any) => {
      if (
        data?.recipientAddress?.address === rawAddress.split('-').join('') &&
        !!data?.transactionInfo?.hash &&
        data?.message?.type < 0
      ) {
        console.log('New confirmed transaction!', 'background: green');
      }
      this.showMessage('notification.newConfirmed', 'success');
    });
  };

  public addUnconfirmed = (rawAddress: string): void => {
    const address = Address.createFromRawAddress(rawAddress);
    this.listener.unconfirmedAdded(address).subscribe(() => {
      console.log('warning: LOAD_ALL_DATA');
    });
  };

  public showMessage = (message: string, type: 'danger' | 'warning' | 'success' = 'success'): void => {
    let newTpe = 'notification';
    switch (type) {
      case 'danger':
        newTpe = 'error';
        break;
      case 'success':
        newTpe = 'info';
        break;
      case 'warning':
        newTpe = 'notification';
        break;
    }

    if (type === 'danger') {
      this.onError(message);
    } else if (type === 'success') {
      this.onSuccess();
    }
  };
  public close = (): void => this.listener?.close();
}
