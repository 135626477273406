import React from 'react';
import { ShowHeader } from 'components/ShowHeader';
import { DynamicForm } from '../../components/DynamicForm';
import { FormConfig } from '../../components/DynamicForm';
import { useHistory } from 'react-router-dom';
import { DynamicFormWide } from 'components/DynamicForm/DynamicFormWide';

type IEditSection = {
  url: string;
  id?: string;
  title: string;
  config?: FormConfig<any, any>;
  formTitles?: string[];
  wide?: Boolean;
  dataType?:
    | 'car-part'
    | 'event'
    | 'car'
    | 'organization'
    | 'user'
    | 'maintenance'
    | 'task'
    | 'task template'
    | 'dictionary';
};

export const EditSection = ({ id, title, config, formTitles, dataType, wide = false }: IEditSection) => {
  const { goBack } = useHistory();
  return (
    <div>
      <ShowHeader title={title} />
      {config && id && wide ? (
        <DynamicFormWide
          onSuccessUpdated={() => goBack()}
          id={id}
          mode={'edit'}
          config={config}
          titles={formTitles ? formTitles : []}
          dataType={dataType}
        />
      ) : (
        ''
      )}
      {config && id && !wide ? (
        <DynamicForm onSuccessUpdated={() => goBack()} id={id} mode={'edit'} config={config} dataType={dataType} />
      ) : (
        ''
      )}
    </div>
  );
};
