import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { DictionaryList } from './DictionaryList/DictionaryList';
import { DictionaryValueList } from './DictionaryList/DictionaryValueList';
import { DictionaryForm } from './DictionaryForm/DictionaryForm';

export const Dictionaries = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <DictionaryList />;
  } else if (content === PageContenEnum.SHOW) {
    return <DictionaryValueList id={id} />;
  } else if (content === PageContenEnum.EDIT) {
    return <DictionaryForm dictionaryId={id} mode="edit" />;
  }

  return <DictionaryForm dictionaryId={id} mode="create" />;
};
