import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'store';
import { FormControl, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    marginRight: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  organizationId?: string;
  onChange: (organizationId: string) => void;
};

export const OrganizationSelect: React.FC<Props> = ({ organizationId, onChange }) => {
  const classes = useStyles();
  const {
    state: { organizations },
  } = useStore();

  const handleChange = (e: any) => {
    onChange(e?.target?.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="organization-select-label">Organization</InputLabel>
      <Select
        value={organizationId}
        onChange={handleChange}
        labelId="organization-select-label"
        variant={'standard'}
        style={{ minWidth: 250 }}>
        {organizations.data.map((organization) => (
          <MenuItem
            defaultValue={organizationId}
            selected={organization.id === organizationId}
            key={organization.id}
            value={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
