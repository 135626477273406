import { useHistory, useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { useDeleteModal } from 'hooks/useDeleteModal';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
      background: 'none',
    },
    rightSidePaper: {
      display: 'flex',
      background: 'none',
    },
    link: {
      textDecoration: 'none',
    },
    editButton: {
      marginRight: 15,
      color: '#fff',
      backgroundColor: '#1554d5',
      '&:hover': {
        backgroundColor: 'rgb(21 84 213 / 79%)',
      },
    },
  })
);

type IShowHeader = {
  title: string;
};

export const ShowHeader = ({ title }: IShowHeader) => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { content }: any = useParams();

  const { handleModalOpen, Modal } = useDeleteModal();

  return (
    <Paper elevation={0} className={classes.root}>
      <Button startIcon={<KeyboardBackspaceIcon />} onClick={() => goBack()}>
        Back
      </Button>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Paper elevation={0} className={classes.rightSidePaper}>
        {content === PageContenEnum.SHOW && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => handleModalOpen(true)}>
            Delete
          </Button>
        )}
      </Paper>
      <Modal text="dsdss" />
    </Paper>
  );
};
