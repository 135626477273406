import React, { useEffect } from 'react';
import { ShowHeader } from 'components/ShowHeader';
import { DynamicForm } from '../../components/DynamicForm';
import { FormConfig } from '../../components/DynamicForm';
import { useHistory } from 'react-router-dom';
import { DynamicFormWide } from 'components/DynamicForm/DynamicFormWide';
import { useStore } from 'store';

type IAddSection = {
  title: string;
  config?: FormConfig<any, any>;
  successCreatedCB?: (entityId: string, func: () => void) => void;
  wide?: Boolean;
  formTitles?: string[];
  dataType?:
    | 'car-part'
    | 'event'
    | 'car'
    | 'organization'
    | 'user'
    | 'maintenance'
    | 'task'
    | 'task template'
    | 'dictionary';
};

export const AddSection = ({ formTitles, title, config, successCreatedCB, dataType, wide = false }: IAddSection) => {
  const { goBack } = useHistory();
  const {
    actions: { getOrganizations },
  } = useStore();

  useEffect(() => {
    if (dataType === 'user') {
      getOrganizations();
    }
  }, [dataType]);

  const onSuccess = (v: any) => {
    if (successCreatedCB) {
      successCreatedCB(v, goBack);
    } else {
      goBack();
    }
  };

  return (
    <div>
      <ShowHeader title={title} />
      {config && wide ? (
        <DynamicFormWide
          onSuccessCreated={onSuccess}
          mode={'create'}
          config={config}
          titles={formTitles ? formTitles : []}
          dataType={dataType}
        />
      ) : (
        ''
      )}
      {config && !wide ? (
        <DynamicForm onSuccessCreated={onSuccess} mode={'create'} config={config} dataType={dataType} />
      ) : (
        ''
      )}
    </div>
  );
};
