import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import BusinessIcon from '@material-ui/icons/Business';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleIcon from '@material-ui/icons/People';
import BuildIcon from '@material-ui/icons/Build';
import FlagIcon from '@material-ui/icons/Flag';

import { Cars, Tasks, Users, Events, CarParts, Maintenance, Dictionaries, Organizations, TaskTemplates } from 'pages';
import { RolesTypeEnum } from 'utils/filterColumns';

export const routes = [
  {
    name: 'Organizations',
    path: '/organizations',
    Component: Organizations,
    Icon: BusinessIcon,
    access: [RolesTypeEnum.ADMIN],
  },
  {
    name: 'Users',
    path: '/users',
    Component: Users,
    Icon: PeopleIcon,
    access: [RolesTypeEnum.ADMIN, RolesTypeEnum.ORGANIZATION_ADMIN],
  },
  { name: 'Events', path: '/events', Component: Events, Icon: FlagIcon, access: [RolesTypeEnum.ORGANIZATION_ADMIN] },
  {
    name: 'Maintenance',
    path: '/maintenance',
    Component: Maintenance,
    Icon: SettingsApplicationsIcon,
    access: [RolesTypeEnum.ORGANIZATION_ADMIN],
  },
  {
    name: 'Tasks',
    path: '/tasks',
    Component: Tasks,
    Icon: FormatListBulletedIcon,
    access: [RolesTypeEnum.ORGANIZATION_ADMIN],
  },
  {
    name: 'Tasks Templates',
    path: '/task-templates',
    Component: TaskTemplates,
    Icon: ListAltIcon,
    access: [RolesTypeEnum.ORGANIZATION_ADMIN],
  },
  { name: 'Cars', path: '/cars', Component: Cars, Icon: DirectionsCarIcon, access: [RolesTypeEnum.ORGANIZATION_ADMIN] },
  {
    name: 'Car Parts',
    path: '/car-parts',
    Component: CarParts,
    Icon: BuildIcon,
    access: [RolesTypeEnum.ORGANIZATION_ADMIN],
  },
  {
    name: 'Dictionaries',
    path: '/dictionaries',
    Component: Dictionaries,
    Icon: NotificationsIcon,
    access: [RolesTypeEnum.ORGANIZATION_ADMIN],
  },
];

export const defaultRoute = routes[0];

export const getRouteByRole = (role: RolesTypeEnum) => {
  switch (role) {
    case RolesTypeEnum.ORGANIZATION_ADMIN:
      return routes[1];
    case RolesTypeEnum.ADMIN:
      return routes[0];
    default:
      return defaultRoute;
  }
};
