import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FieldComponentProps } from './types';
import { CarPartAllocationConfig, SelectFieldOption } from '../index';

export const CarPartAllocationControl: React.FC<FieldComponentProps<CarPartAllocationConfig>> = ({
  field,
  value,
  onChange,
  onBlur,
  error,
}) => {
  const { state } = useStore();
  const [targetOptions, setTargetOptions] = useState<SelectFieldOption[]>([]);
  const [defaultVal, setDefaultVal] = useState({ type: '', targetId: '' });
  if (value && !defaultVal.type) {
    setDefaultVal({
      type: value?.type,
      targetId: value?.targetId,
    });
  }

  useEffect(() => {
    switch (value?.type) {
      case 'stock':
        setTargetOptions(field.getStockTypeOptions(state));
        break;
      case 'car_part':
        field.getCarPartOptions(state).then(setTargetOptions);
        break;
      case 'car':
        field.getCarOptions(state).then(setTargetOptions);
        break;
    }
  }, [value?.type]);

  return (
    <FormControl fullWidth>
      <InputLabel id={field.name as string}>{field.label}</InputLabel>
      <Select
        error={!!error}
        labelId={field.name as string}
        value={defaultVal?.type}
        defaultValue={defaultVal.type}
        inputProps={{
          id: defaultVal.type as string,
        }}
        onBlur={onBlur}
        onChange={(e) =>
          onChange({
            targetId: null,
            type: e.target.value,
          })
        }
        fullWidth>
        <MenuItem value={'stock'}>Stock</MenuItem>
        <MenuItem value={'car_part'}>Car part</MenuItem>
        <MenuItem value={'car'}>Car</MenuItem>
      </Select>
      <br />
      <Select
        error={!!error}
        labelId={field.name as string}
        value={defaultVal?.targetId}
        defaultValue={defaultVal?.targetId}
        onBlur={onBlur}
        onChange={(e) =>
          onChange({
            targetId: e.target.value,
            type: value?.type,
          })
        }
        fullWidth>
        {targetOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};
