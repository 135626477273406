import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { CAR_URL } from 'store/api';

import { ListSection } from 'sections/ListSection';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { CAR_FORM_CONFIG } from './car-form.config';
import { CAR_DETAILS_CONFIG } from './car-details.config';

export const Cars = () => {
  const { content, id }: any = useParams();
  if (!content || content === PageContenEnum.LIST) {
    return <ListSection placeholder="Search by name or code" url={CAR_URL} columnName="cars" />;
  } else if (content === PageContenEnum.SHOW) {
    return <ShowSection url={CAR_URL} config={CAR_DETAILS_CONFIG} id={id} title="Car Details" />;
  } else if (content === PageContenEnum.EDIT) {
    return <EditSection id={id} url={CAR_URL} title="Edit Car" config={CAR_FORM_CONFIG} />;
  }

  return <AddSection title="Create Car" config={CAR_FORM_CONFIG} />;
};
