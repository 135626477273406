import { IDictionaryValue } from 'types/dictionaries';
import { IMaintenance } from 'types/maintenance';
import { IUser } from 'types/users';
import { ICar } from 'types/cars';

export enum RoleEnum {
  organization_admin = 'organization_admin',
  mechanic = 'mechanic',
  engineer = 'engineer',
  admin = 'admin',
}

export type CarMileage = {
  id: string;
  createdAt: string;
  creator: IUser;
  car: ICar;
  newDistance: number;
  newTime: number;
  previousDistance: number;
  previousTime: number;
  updatedAt: string;
};

export type EventDetails = {
  id: string;
  name: string;
  trackName: string;
  dateStart: string;
  dateEnd: string;
  createdAt: Date;
  updatedAt: Date;
  type?: IDictionaryValue;
  championship?: IDictionaryValue;
  status?: IDictionaryValue;
  user?: IUser;
  maintenances: IMaintenance[];
  mileages?: CarMileage[];
  code?: string;
};
