import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { IColumn, MuiTable } from 'components/Table';
import { Search } from 'components/Search';
import { FilterMenu } from 'components/FilterMenu';
import { filterColumns, RolesTypeEnum } from 'utils/filterColumns';
import { useFetchOnce } from 'hooks/useFetchOnce';
import { useMount } from 'hooks/useMount';
import { useDebounce } from 'hooks/useDebounce';
import { tableColumns } from 'utils/columns';
import { PageContenEnum } from 'utils/contstants';
import { IDictionary } from 'types/dictionaries';
import { OrganizationSelect } from '../../components/OrganizationSelect';
import { useStore } from '../../store';
import { EmptyPlaceholder } from '../../components/EmptyPlaceholder';

interface IListSection {
  url: string;
  columnName: string;
  isDictionary?: boolean;
  columns?: IColumn[];
  placeholder?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      background: 'none',
      paddingBottom: 20,
    },
    searchWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    paper: {
      background: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    button: {
      color: '#1554d5',
      marginTop: 16,
    },
  })
);

export const ListSection = ({ url, columnName, isDictionary, columns, placeholder }: IListSection) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    state: { user },
  } = useStore();

  const [organizationId, setOrganization] = useState<string>();

  const [extraFilters, setExtraFilters] = useState({});

  const [res, fetchData, isBusy] = useFetchOnce([], url);

  const debouncedValue = useDebounce(searchValue, 500);

  const filterParams = useMemo(
    () => ({
      offset: 0,
      limit: 100,
      organizationId,
      ...extraFilters,
      // eslint-disable-next-line
      ...(debouncedValue && { ['filter[search]']: `%${debouncedValue}%` }),
    }),
    [debouncedValue, organizationId, extraFilters]
  );

  useMount(() => {
    fetchData({ limit: 100, offset: 0 });
  });

  useEffect(() => {
    fetchData(filterParams);
  }, [filterParams, fetchData]);

  const data = useMemo(() => {
    if (!res?.data?.length) return [];

    return isDictionary ? res?.data.flatMap(({ values }: IDictionary) => values) : res?.data;
  }, [res, isDictionary]);

  const onFilterChange = useCallback(
    (filterKey: string, value: unknown) => {
      setExtraFilters({
        ...extraFilters,
        [filterKey]: value === '' ? undefined : value,
      });
    },
    [filterParams, fetchData]
  );

  return (
    <div>
      <Paper elevation={0} className={classes.root}>
        <div>
          <div className={classes.searchWrap}>
            <Search placeholder={placeholder} onChange={setSearchValue} />
            <Link className={classes.link} to={`${columnName}/${PageContenEnum.ADD}`}>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                className={classes.button}
                variant="outlined"
                color="primary">
                Create
              </Button>
            </Link>
          </div>
          <div>
            <Paper elevation={0} className={classes.paper}>
              {user?.role === RolesTypeEnum.ADMIN && (
                <OrganizationSelect organizationId={organizationId} onChange={setOrganization} />
              )}
              {filterColumns?.[columnName].map((item: any) => (
                <FilterMenu
                  key={item.filterKey}
                  url={item.url}
                  title={item.title}
                  customData={item.customData}
                  filterKey={item.filterKey}
                  onChange={onFilterChange}
                  dictionaryName={item.dictionaryName}
                />
              ))}
            </Paper>
          </div>
        </div>
      </Paper>
      {data.length === 0 && searchValue !== '' ? (
        <EmptyPlaceholder />
      ) : (
        <MuiTable columns={columns || tableColumns?.[columnName] || []} isBusy={isBusy} data={data} />
      )}
    </div>
  );
};
