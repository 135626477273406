import { useStore } from 'store';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes, getRouteByRole } from 'utils/routes';
import { isLocalhost } from 'utils/helpers';

export const RoutesComponent = () => {
  const {
    state: { user },
  } = useStore();

  return (
    user && (
      <Switch>
        {routes
          .filter((route) => route.access.includes(user.role) || isLocalhost())
          .map(({ path, Component }, index) => (
            <Route exact key={index} path={`${path}/:content?/:id?/:subId?`} component={Component} />
          ))}
        <Redirect from="*" to={getRouteByRole(user.role).path} />
      </Switch>
    )
  );
};
