import { useEffect, useState } from 'react';
import { Api } from '../../../utils/Api';
import { CAR_PART_CATEGORY_URL } from '../../../store/api';
import { List } from '../../../types/list';

const limit = 1000;
export const usePartCategory = (): [{ value: string; label: string }[], { value: string; label: string }[]] => {
  const [offset, setOffset] = useState<number>(0);
  const [categories, setCategories] = useState<{ value: string; label: string }[]>([]);
  const [subCategories, setSubCategories] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    Api.get<List<any>>(CAR_PART_CATEGORY_URL, {
      offset: 0,
      limit,
    }).then((response) => {
      setCategories((items) =>
        items.concat(
          response.data.data.filter(({ parent }) => parent === null).map(({ id, name }) => ({ value: id, label: name }))
        )
      );
      setSubCategories((items) =>
        items.concat(
          response.data.data.filter(({ parent }) => parent !== null).map(({ id, name }) => ({ value: id, label: name }))
        )
      );
      if (offset < response.data.total - limit) {
        setOffset(offset + limit);
      }
    });
  }, [offset]);
  return [categories, subCategories];
};
