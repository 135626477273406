import { ExtendedKey, MnemonicPassPhrase, Network, Wallet } from 'symbol-hd-wallets';
import {
  Account,
  AccountHttp,
  Address,
  Mosaic,
  MosaicHttp,
  MosaicId,
  NamespaceHttp,
  NetworkType,
  UInt64,
} from 'symbol-sdk';

import { AccountSecureStorage } from '../persistence/AccountSecureStorage';

import { AccountOriginType, IAccountModel } from '../../types/models/AccountModel';
import { IMnemonicModel } from '../../types/models/MnemonicModel';
import { AppNetworkType, INetworkModel } from '../../types/models/NetworkModel';
import { IMosaicModel } from 'types/models/MosaicModel';
import MosaicService from './MosaicService';

export default class AccountService {
  /**
   * Generates random mnemonic
   */
  public static createRandomMnemonic(): IMnemonicModel {
    return {
      mnemonic: MnemonicPassPhrase.createRandom().plain,
      lastIndexDerived: 0,
    };
  }

  /**
   * Get native mosaic Id
   * @param network
   * @returns {Promise<{amount: string, mosaicId: string, mosaicName: (*|null), divisibility: *}>}
   */
  public static async getNativeMosaicModel(network: INetworkModel): Promise<IMosaicModel> {
    let mosaicInfo: any = {
        divisibility: 0,
      },
      mosaicName: any = {
        names: [],
      };
    const mosaic: any = new Mosaic(new MosaicId(network.currencyMosaicId), UInt64.fromUint(0));
    const mosaicId: any = mosaic.id;
    const amount: any = mosaic.amount;
    try {
      if (mosaic.id instanceof MosaicId) {
        mosaicInfo = await new MosaicHttp(network.node).getMosaic(mosaic.id).toPromise();
      }
      const resp: any = await new NamespaceHttp(network.node).getMosaicsNames([mosaicId]).toPromise();
      [mosaicName] = resp;
    } catch (e) {
      console.error(e, 236);
    }
    const resp: IMosaicModel = {
      mosaicId: mosaic.id.toHex(),
      mosaicName: mosaicName.names[0] ? mosaicName.names[0].name : '',
      amount,
      divisibility: mosaicInfo.divisibility,
    };
    return resp;
  }

  /**
   * Get account from private key
   */
  public static getAddressFromPrivateKey = (privateKey: string, network: AppNetworkType): string =>
    Account.createFromPrivateKey(privateKey, AccountService._appNetworkToNetworkType(network)).address.pretty();

  /**
   * Returns balance from a given Address and a node
   * @param address
   * @param network
   * @returns {Promise<number>}
   */
  public static getBalanceAndOwnedMosaicsFromAddress = async (
    address: string,
    network: INetworkModel
  ): Promise<{ balance: number; ownedMosaics: IMosaicModel[] }> => {
    try {
      const accountInfo: any = await new AccountHttp(network.node)
        .getAccountInfo(Address.createFromRawAddress(address))
        .toPromise();
      let amount = 0,
        hasCurrencyMosaic = false;
      const ownedMosaics: IMosaicModel[] = [];
      for (const mosaic of accountInfo.mosaics) {
        const mosaicModel = await MosaicService.getMosaicModelFromMosaicId(mosaic, network);
        if (mosaic.id.toHex() === network.currencyMosaicId) {
          hasCurrencyMosaic = true;
          amount = mosaic.amount.compact() / Math.pow(10, mosaicModel.divisibility);
        }
        ownedMosaics.push(mosaicModel);
      }
      if (!hasCurrencyMosaic) {
        const currencyMosaic = await AccountService.getNativeMosaicModel(network);
        ownedMosaics.push(currencyMosaic);
      }
      return {
        balance: amount,
        ownedMosaics: ownedMosaics,
      };
    } catch (e) {
      // const currencyMosaic = await this.getNativeMosaicModel(network); // TODO: runs loop calls
      return { balance: 0, ownedMosaics: [] };
    }
  };

  /**
   * App network to symbol network type
   * @param net
   * @returns {NetworkType.TEST_NET|NetworkType.MAIN_NET}
   * @private
   */
  public static _appNetworkToNetworkType(net: AppNetworkType): NetworkType {
    return net === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
  }

  /**
   * Create account
   * @returns {{privateKey: string, address: string, publicKey: string}}
   */
  public static createAccount(network: NetworkType): { privateKey: string; address: string; publicKey: string } {
    const account = Account.generateNewAccount(network);

    return {
      address: account.address.pretty(),
      privateKey: account.privateKey,
      publicKey: account.publicKey,
    };
  }

  /**
   * Generates random mnemonic
   */
  public static getAddressByAccountModelAndNetwork = (accountModel: IAccountModel, network: AppNetworkType): string =>
    Account.createFromPrivateKey(
      accountModel.privateKey,
      AccountService._appNetworkToNetworkType(network)
    ).address.pretty();

  /**
   * Remove account by it's id
   */
  public static async removeAccountById(id: string, network: AppNetworkType): Promise<IAccountModel[]> {
    return AccountSecureStorage.removeAccount(id, network);
  }

  /**
   * Renames account by it's id
   */
  public static async renameAccount(id: string, newName: string, network: AppNetworkType): Promise<IAccountModel[]> {
    const allAccounts = await AccountSecureStorage.getAllAccountsByNetwork(network);
    const account = allAccounts.find((account: { id: string }) => account.id === id);
    if (account) {
      account.name = newName;
      await AccountSecureStorage.updateAccount(account);
    }
    return AccountSecureStorage.getAllAccountsByNetwork(network);
  }

  /**
   * Updates persistent del request sent
   */
  // public static async updateDelegatedHarvestingInfo(
  //   id: string,
  //   isPersistentDelReqSent: boolean,
  //   harvestingNode: string,
  //   network: AppNetworkType
  // ): Promise<Promise<IAccountModel[]> | undefined> {
  //   const allAccounts = await AccountSecureStorage.getAllAccountsByNetwork(network);
  //   const account = allAccounts.find((account: { id: string }) => account.id === id);
  //   if (!account) {
  //     return;
  //   }
  //   account.isPersistentDelReqSent = isPersistentDelReqSent;
  //   account.harvestingNode = harvestingNode;
  //   await AccountSecureStorage.updateAccount(account);
  //   return AccountSecureStorage.getAllAccountsByNetwork(network);
  // }

  /**
   * Creates an account from a mnemonic
   * @param mnemonic
   * @param index
   * @param name
   * @param network
   * @param curve
   * @returns {AccountModel}
   */
  public static createFromMnemonicAndIndex(
    mnemonic: string,
    index: number,
    name: string,
    network: AppNetworkType,
    curve = Network.SYMBOL
  ): IAccountModel {
    const mnemonicPassPhrase = new MnemonicPassPhrase(mnemonic);
    const seed = mnemonicPassPhrase.toSeed().toString('hex');
    const extKey = ExtendedKey.createFromSeed(seed, curve);
    const wallet = new Wallet(extKey);
    const path = `m/44'/${network === 'testnet' ? '1' : '4343'}'/${index}'/0'/0'`;
    const privateKey = wallet.getChildAccountPrivateKey(path);
    const symbolAccount = Account.createFromPrivateKey(
      privateKey,
      network === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
    );
    return this.symbolAccountToAccountModel(symbolAccount, name, curve === Network.SYMBOL ? 'hd' : 'optin', path);
  }

  /**
   * Creates an account from a mnemonic
   * @param privateKey
   * @param name
   * @param network
   * @returns {AccountModel}
   */
  public static createFromPrivateKey(privateKey: string, name: string, network: AppNetworkType): IAccountModel {
    const symbolAccount = Account.createFromPrivateKey(
      privateKey,
      network === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
    );
    return this.symbolAccountToAccountModel(symbolAccount, name, 'privateKey');
  }

  /**
   * Returns balance from a given Address and a node
   * @param address
   * @param network
   * @returns {Promise<number>}
   */
  // public static getBalanceAndOwnedMosaicsFromAddress = async (
  //   address: string,
  //   network: INetworkModel
  // ): Promise<{ balance: number; ownedMosaics: IMosaicModel[] }> => {
  //   try {
  //     const accountInfo: IAccountHttp = await new AccountHttp(network.node)
  //       .getAccountInfo(Address.createFromRawAddress(address))
  //       .toPromise();
  //     let amount = 0,
  //       hasCurrencyMosaic = false;
  //     const ownedMosaics: IMosaicModel[] = [];
  //     if (accountInfo) {
  //       for (const mosaic of accountInfo.mosaics) {
  //         const mosaicModel = await MosaicService.getMosaicModelFromMosaicId(mosaic, network);
  //         if (mosaic.id.toHex() === network.currencyMosaicId) {
  //           hasCurrencyMosaic = true;
  //           amount = mosaic.amount.compact() / Math.pow(10, mosaicModel.divisibility);
  //         }
  //         ownedMosaics.push(mosaicModel);
  //       }
  //     }

  //     if (!hasCurrencyMosaic) {
  //       const currencyMosaic = await AccountService.getNativeMosaicModel(network);
  //       ownedMosaics.push(currencyMosaic);
  //     }
  //     return {
  //       balance: amount,
  //       ownedMosaics: ownedMosaics,
  //     };
  //   } catch (e) {
  //     return { balance: 0, ownedMosaics: [] };
  //   }
  // };

  /**
   * Get native mosaic Id
   * @param network
   * @returns {Promise<{amount: string, mosaicId: string, mosaicName: (*|null), divisibility: *}>}
   */
  // public static async getNativeMosaicModel(network: INetworkModel): Promise<IMosaicModel> {
  //   let mosaicInfo = {
  //       divisibility: 0,
  //     },
  //     mosaicName: { names: { name: string }[] } = {
  //       names: [],
  //     };
  //   const mosaic: Mosaic = new Mosaic(new MosaicId(network.currencyMosaicId), UInt64.fromUint(0));
  //   const mosaicId: MosaicId | any = mosaic.id;
  //   const amount: UInt64 = mosaic.amount;
  //   try {
  //     if (mosaic.id instanceof MosaicId) {
  //       mosaicInfo = await new MosaicHttp(network.node).getMosaic(mosaic.id).toPromise();
  //     }
  //     [mosaicName] = await new NamespaceHttp(network.node).getMosaicsNames([mosaicId]).toPromise();
  //   } catch (e) {
  //     console.error(e, 236);
  //   }
  //   const resp: IMosaicModel = {
  //     mosaicId: mosaic.id.toHex(),
  //     mosaicName: mosaicName.names[0] ? mosaicName.names[0].name : '',
  //     amount,
  //     divisibility: mosaicInfo.divisibility,
  //   };
  //   return resp;
  // }

  /**
   * Transform a symbol account to an account Model
   * @param account
   * @param name
   * @param type
   * @param path
   * @returns {{privateKey: string, name: string, id: string, type: AccountOriginType}}
   */
  public static symbolAccountToAccountModel(
    account: Account,
    name: string,
    type: AccountOriginType,
    path?: string
  ): IAccountModel {
    return {
      id: account.publicKey,
      name: name,
      type: type,
      privateKey: account.privateKey,
      path: path,
      network: account.networkType === NetworkType.TEST_NET ? 'testnet' : 'mainnet',
    };
  }

  /**
   * Gets multisig information
   * @param address
   * @param network
   * @returns {Promise<*[]>}
   */
  // public static getCosignatoryOfByAddress = async (
  //   address: string,
  //   network: INetworkModel
  // ): Promise<{ cosignatoryOf: string[]; isMultisig: boolean }> => {
  //   try {
  //     const multisigInfo = await new MultisigHttp(network.node)
  //       .getMultisigAccountInfo(Address.createFromRawAddress(address))
  //       .toPromise();
  //     return {
  //       cosignatoryOf: multisigInfo.multisigAddresses.map((address: Address) => address.pretty()),
  //       isMultisig: multisigInfo.cosignatoryAddresses.length > 0,
  //     };
  //   } catch (e) {
  //     return { cosignatoryOf: [], isMultisig: false };
  //   }
  // };
  /**
   * Gets multisig information
   * @returns {Promise<*[]>}
   * @param mnemonic
   * @param accounts
   * @param network
   */
  // public static async generatePaperWallet(
  //   mnemonic: string,
  //   accounts: IAccountModel[],
  //   network: INetworkModel
  // ): Promise<string> {
  //   const mnemonicAccount = this.createFromMnemonicAndIndex(mnemonic, 0, 'Root', network.type);
  //   const hdRootAccount = {
  //     mnemonic: mnemonic,
  //     rootAccountPublicKey: mnemonicAccount.id,
  //     rootAccountAddress: this.getAddressByAccountModelAndNetwork(mnemonicAccount, network.type),
  //   };
  //   const privateKeyAccounts = accounts.map((account) => ({
  //     name: account.name,
  //     address: this.getAddressByAccountModelAndNetwork(account, network.type),
  //     publicKey: account.id,
  //     privateKey: account.privateKey,
  //   }));

  //   const paperWallet = new SymbolPaperWallet(
  //     hdRootAccount,
  //     privateKeyAccounts,
  //     network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET,
  //     network.generationHash
  //   );

  //   const bytes: Uint8Array = await paperWallet.toPdf();
  //   const Uint8ToString = (u8a: Uint8Array): string => {
  //     const CHUNK_SZ = 0x8000;
  //     const c = [];
  //     for (let i = 0; i < u8a.length; i += CHUNK_SZ) {
  //       const arg: any = u8a.subarray(i, i + CHUNK_SZ);
  //       c.push(String.fromCharCode.apply(null, arg));
  //     }
  //     return c.join('');
  //   };
  //   const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  //   const btoa = (input = ''): string => {
  //     const str = input;
  //     let output = '';

  //     for (
  //       let block = 0, charCode, i = 0, map = chars;
  //       str.charAt(i | 0) || ((map = '='), i % 1);
  //       output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
  //     ) {
  //       charCode = str.charCodeAt((i += 3 / 4));

  //       if (charCode > 0xff) {
  //         throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
  //       }
  //       block = (block << 8) | charCode;
  //     }

  //     return output;
  //   };

  //   return btoa(Uint8ToString(bytes));
  // }
}
