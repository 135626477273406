import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { MAINTENANCE_URL } from 'store/api';

import { ListSection } from 'sections/ListSection';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { MAINTENANCE_FORM_CONFIG } from './maintenance-form.config';
import { MAINTENANCE_DETAILS_CONFIG } from './maintenance-details-config';

export const Maintenance = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <ListSection placeholder="Search by name or code" url={MAINTENANCE_URL} columnName="maintenance" />;
  } else if (content === PageContenEnum.SHOW) {
    return (
      <ShowSection url={MAINTENANCE_URL} config={MAINTENANCE_DETAILS_CONFIG} id={id} title="Maintenance Details" />
    );
  } else if (content === PageContenEnum.EDIT) {
    return <EditSection id={id} url={MAINTENANCE_URL} title="Edit Maintenance" config={MAINTENANCE_FORM_CONFIG} />;
  }

  return <AddSection title="Create Maintenance" config={MAINTENANCE_FORM_CONFIG} dataType="maintenance" />;
};
