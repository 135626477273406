import dayjs from 'dayjs';

type DateTimeString = {
  getTime: () => string;
  getDate: () => string;
};

export const dateTimeString = (dateTimeString: string): DateTimeString => {
  const date = dayjs(dateTimeString);

  const getTime = (): string => {
    return date.format('HH:mm');
  };

  const getDate = (): string => {
    return date.format('YYYY-MM-DD');
  };

  return {
    getTime,
    getDate,
  };
};
