import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { DynamicFormControl } from './FormControls';
import { BaseValues, FormConfig, useConfigValidationSchema } from './config';
import { showError } from 'utils/helpers';
import { useStore } from '../../store';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListDataProvider, useListDataProvider } from 'hooks/useListDataProvider';
import { EventDetails } from 'types/events';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: '#ff4d4d',
      marginRight: 16,
    },
  })
);

export type DynamicFormWideProps<S extends BaseValues, V extends BaseValues> = {
  id?: string;
  titles: string[];
  mode: 'edit' | 'create';
  config: FormConfig<S, V>;
  onSuccessCreated?: (values?: any) => void;
  onSuccessUpdated?: (values?: any) => void;
  dataType?:
    | 'car-part'
    | 'event'
    | 'car'
    | 'organization'
    | 'user'
    | 'maintenance'
    | 'task'
    | 'task template'
    | 'dictionary';
};

const formatDateToIso = <V extends BaseValues>(values: V) => {
  if (values.startDate || values.dueDate) {
    return {
      ...values,
      startDate: values.startDate !== '' ? new Date(values.startDate).toISOString() : '',
      dueDate: values.dueDate !== '' ? new Date(values.dueDate).toISOString() : '',
    };
  }

  return values;
};

const cleanValues = <S extends BaseValues, V extends BaseValues>(values: V, config: FormConfig<S, V>): V => {
  return config.fields.reduce((values, field) => {
    const formattedValues = formatDateToIso(values);

    if (formattedValues[field.name] === '' && !field.required) {
      formattedValues[field.name] = null;
    }
    return formattedValues;
  }, values as any) as V;
};

export const DynamicFormWide = <S extends BaseValues, V extends BaseValues>({
  id,
  config,
  mode,
  onSuccessCreated,
  onSuccessUpdated,
  titles,
  dataType,
}: DynamicFormWideProps<S, V>) => {
  const {
    state,
    actions: { changeEventsList },
  } = useStore();
  const { goBack } = useHistory();
  const validationSchema = useConfigValidationSchema(config, mode, state);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const classes = useStyles();
  const listData =
    dataType === 'maintenance'
      ? useListDataProvider(config.additionalDataProvider as ListDataProvider<any>, {
          limit: 200,
        })
      : () => ({});

  const formik = useFormik<V>({
    validationSchema,
    initialValues: config.defaultValues,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const cleanedValues = cleanValues(values, config);
      setIsDisabled(true);
      switch (mode) {
        case 'edit':
          id &&
            config.dataSource
              .update(id, cleanedValues)
              .then((res) => onSuccessUpdated && onSuccessUpdated(res?.data))
              .catch((err: any) => showError(err?.response?.data?.message))
              .finally(() => setIsDisabled(false));
          break;
        case 'create':
          config.dataSource
            .create(cleanedValues)
            .then((res) => onSuccessCreated && onSuccessCreated(res?.data))
            .catch((err: any) => showError(err?.response?.data?.message))
            .finally(() => setIsDisabled(false));
          break;
      }
    },
  });
  console.log('>>>Config', config, mode);
  const { setFieldValue, values, handleSubmit, setFormikState, errors, validateField } = formik;

  useEffect(() => {
    if (dataType === 'maintenance' && config.additionalDataProvider && values.organizationId) {
      // @ts-ignore
      listData?.onChangeOrganization(values.organizationId);
    }
  }, [config, dataType, values]);

  useEffect(() => {
    if (dataType === 'maintenance') {
      // @ts-ignore
      changeEventsList({ data: listData?.data as EventDetails[], total: Number(listData.total) });
    }
    // @ts-ignore
  }, [listData?.data]);

  useEffect(() => {
    const { name, dateStart, dateEnd, typeId } = values;

    if (dataType === 'event') {
      name && dateStart && dateEnd && typeId ? setIsDisabled(false) : setIsDisabled(true);
    }
  }, [values]);

  useEffect(() => {
    if (id && mode === 'edit') {
      config.dataSource.get(id).then((data) => {
        setFormikState((state) => ({
          ...state,
          values: config.mapSourceToValues(data),
        }));
      });
    }
  }, [config, id, mode, setFormikState]);

  return (
    <Container component="main" maxWidth={'xl'}>
      <Paper variant="elevation">
        <Box p={2} pb={3} pt={3}>
          <Grid container spacing={3}>
            <Grid xs={4} item spacing={1}>
              <Typography variant="subtitle1" component="h6">
                {titles[0]}
              </Typography>
              <br></br>
              {config.fields
                .slice(0, 6)
                .filter(({ isDisabled }) => !(isDisabled && isDisabled(state, mode)))
                .map((field, index) => {
                  return (
                    <Grid key={index} xs={10} sm={10} md={10} lg={10} item>
                      <DynamicFormControl<V>
                        values={values}
                        errors={errors}
                        value={
                          field.name === 'startDate' || field.name === 'dueDate'
                            ? values[field.name].split('T')[0]
                            : values[field.name]
                        }
                        error={errors[field.name as keyof V]}
                        onChange={(value) => setFieldValue(field.name as string, value)}
                        onBlur={() => validateField(field.name as string)}
                        field={field}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Grid xs={4} item spacing={1}>
              <Typography variant="subtitle1" component="h6">
                {titles[1]}
              </Typography>
              <br></br>
              {config.fields
                .slice(6, 12)
                .filter(({ isDisabled }) => !(isDisabled && isDisabled(state, mode)))
                .map((field, index) => {
                  return (
                    <Grid key={index} xs={10} sm={10} md={10} lg={10} item>
                      <DynamicFormControl<V>
                        values={values}
                        errors={errors}
                        value={values[field.name]}
                        error={errors[field.name as keyof V]}
                        onChange={(value) => setFieldValue(field.name as string, value)}
                        onBlur={() => validateField(field.name as string)}
                        field={field}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Grid xs={4} item spacing={1}>
              <Typography variant="subtitle1" component="h6">
                {titles[2]}
              </Typography>
              <br></br>
              {config.fields
                .slice(12, 22)
                .filter(({ isDisabled }) => !(isDisabled && isDisabled(state, mode)))
                .map((field, index) => {
                  return (
                    <Grid key={index} xs={10} sm={10} md={10} lg={10} item>
                      <DynamicFormControl<V>
                        values={values}
                        errors={errors}
                        value={values[field.name]}
                        error={errors[field.name as keyof V]}
                        onChange={(value) => setFieldValue(field.name as string, value)}
                        onBlur={() => validateField(field.name as string)}
                        field={field}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Button
                disabled={isDisabled}
                onClick={() => goBack()}
                className={classes.button}
                variant="contained"
                color="primary">
                {'Cancel'}
              </Button>
              <Button disabled={isDisabled} onClick={() => handleSubmit()} variant="contained" color="primary">
                {mode === 'edit' ? 'Save' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};
