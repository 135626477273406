/* eslint-disable no-console */

import { sha256 } from 'js-sha256';
import _ from 'lodash';
import {
  Account,
  Address,
  CosignatureSignedTransaction,
  CosignatureTransaction,
  Deadline,
  EncryptedMessage,
  Message,
  Mosaic,
  MosaicId,
  NetworkType,
  PlainMessage,
  PublicAccount,
  RepositoryFactoryHttp,
  SignedTransaction,
  Transaction,
  TransactionGroup,
  TransactionHttp,
  TransferTransaction,
  UInt64,
} from 'symbol-sdk';

import { generateCleanData } from '../../utils/commonHelpers';

import AccountService from './AccountService';
import { getDefaultNetworkType, getNodes } from './environment';
import ListenerService from './ListenerService';
import NetworkService from './NetworkService';

import { IAccountModel } from '../../types/models/AccountModel';
import { INetworkModel, NetworkState } from '../../types/models/NetworkModel';
import {
  IAggregateTransactionModel,
  ITransactionModel,
  ITransferTransactionModel,
} from '../../types/models/TransactionModel';
import { IMosaicModel } from '../../types/models/MosaicModel';
import { env, NATIVE_MOSAIC_ID } from 'store/env';

export interface IFeesConfig {
  free: number;
  slow: number;
  normal: number;
  fast: number;
}

export const defaultFeesConfig: IFeesConfig = {
  free: 0,
  slow: 1,
  normal: 1.2,
  fast: 2,
};

export default class TransactionService {
  /**
   * Transforms a model to an sdk object
   * @param transaction
   * @param signer
   * @param networkModel
   * @returns {TransferTransaction}
   */
  public static async transactionModelToTransactionObject(
    transaction: ITransactionModel,
    signer: IAccountModel,
    networkModel: INetworkModel
  ): Promise<TransferTransaction> {
    let transactionObj: TransferTransaction;
    if (transaction.type === 'transfer') {
      transactionObj = await this._transferTransactionModelToObject(transaction, signer, networkModel);
    } else {
      throw new Error('Not implemented');
    }

    return transactionObj;
  }

  /**
   * Signs and broadcasts a transaction model
   * @param transaction
   * @param signer
   * @param networkModel
   */
  public static signAndBroadcastTransactionModel = async (
    transaction: ITransactionModel,
    signer: IAccountModel,
    networkModel: INetworkModel
  ): Promise<any> => {
    const transactionObject = await TransactionService.transactionModelToTransactionObject(
      transaction,
      signer,
      networkModel
    );

    return TransactionService._signAndBroadcast(transactionObject, signer, networkModel);
  };

  /**
   * Transforms a transfer transaction model to an sdk object
   * @param transaction
   * @param signer
   * @param networkModel
   * @returns {Promise<TransferTransaction>}
   * @private
   */
  private static _transferTransactionModelToObject = async (
    transaction: ITransactionModel,
    signer: IAccountModel,
    networkModel: INetworkModel
  ): Promise<TransferTransaction> => {
    const recipientAddress: Address | any =
      transaction?.recipientAddress && Address.createFromRawAddress(transaction?.recipientAddress);

    const networkType = networkModel.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const mosaics: Mosaic[] | any = transaction.mosaics && [
      new Mosaic(new MosaicId(transaction.mosaics[0].mosaicId), UInt64.fromUint(transaction.mosaics[0].amount)),
    ];

    if (transaction.messageEncrypted) {
      const signerAccount = Account.createFromPrivateKey(signer.privateKey, networkType);
      const repositoryFactory: any = await new RepositoryFactoryHttp(networkModel.node);
      const accountHttp = repositoryFactory.createAccountRepository();
      try {
        const accountInfo: any = await accountHttp.getAccountInfo(recipientAddress).toPromise();
        const message: Message | any = transaction.messageText
          ? signerAccount.encryptMessage(transaction.messageText, accountInfo)
          : '';
        return TransferTransaction.create(
          Deadline.create(networkModel.epochAdjustment, 2),
          recipientAddress,
          mosaics,
          message,
          networkType,
          UInt64.fromUint(transaction.fee)
        );
      } catch (e) {
        throw Error('Recipient address has not a public key');
      }
    } else {
      const message: Message | any = transaction.messageText && PlainMessage.create(transaction.messageText);
      return TransferTransaction.create(
        Deadline.create(networkModel.epochAdjustment),
        recipientAddress,
        mosaics,
        message,
        networkType,
        UInt64.fromUint(transaction.fee)
      );
    }
  };

  /**
   * Sign and broadcast transaction
   * @returns {UInt64}
   * @param transaction
   * @param signer
   * @param network
   */
  private static async _signAndBroadcast(
    transaction: Transaction,
    signer: IAccountModel,
    network: INetworkModel
  ): Promise<UInt64> {
    const networkType = network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const signerAccount = Account.createFromPrivateKey(signer.privateKey, networkType);
    const signedTransaction = signerAccount.sign(transaction, network.generationHash);
    return this.broadcastSignedTransaction(signedTransaction, network);
  }

  /**
   * Cosign and broadcast aggregate transactionModel
   * @param transaction
   * @param signer
   * @param network
   */
  public static cosignAndBroadcastAggregateTransactionModel(
    transaction: IAggregateTransactionModel,
    signer: IAccountModel,
    network: INetworkModel
  ): Promise<void> {
    const cosignatureTransaction = CosignatureTransaction.create(transaction.signTransactionObject);
    const networkType = network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const signerAccount = Account.createFromPrivateKey(signer.privateKey, networkType);
    const signedTransaction = signerAccount.signCosignatureTransaction(cosignatureTransaction);

    return this.broadcastCosignatureSignedTransaction(signedTransaction, network);
  }

  public static async broadcastSignedTransaction(tx: SignedTransaction, network: INetworkModel): Promise<any> {
    const transactionHttp = new TransactionHttp(network.node);
    return transactionHttp.announce(tx).toPromise();
  }

  public static async broadcastCosignatureSignedTransaction(
    tx: CosignatureSignedTransaction,
    network: INetworkModel
  ): Promise<void> {
    const transactionHttp: any = new TransactionHttp(network.node);
    return transactionHttp.announceAggregateBondedCosignature(tx).toPromise();
  }

  public static calculateMaxFee(
    transaction: Transaction,
    network: INetworkModel,
    selectedFeeMultiplier?: number | string
  ): Transaction {
    if (!selectedFeeMultiplier) {
      return transaction;
    }
    const feeMultiplier =
      TransactionService._resolveFeeMultiplier(network, Number(selectedFeeMultiplier || 0)) <
      network.transactionFees.minFeeMultiplier
        ? network.transactionFees.minFeeMultiplier
        : this._resolveFeeMultiplier(network, Number(selectedFeeMultiplier));
    if (!feeMultiplier) {
      return transaction;
    }
    return transaction.setMaxFee(feeMultiplier);
  }

  private static _resolveFeeMultiplier(network: INetworkModel, selectedFeeMultiplier: number): number {
    if (selectedFeeMultiplier === defaultFeesConfig.slow) {
      const fees =
        network.transactionFees.lowestFeeMultiplier < network.transactionFees.minFeeMultiplier
          ? network.transactionFees.minFeeMultiplier
          : network.transactionFees.lowestFeeMultiplier;
      return fees || network.defaultDynamicFeeMultiplier;
    }
    if (selectedFeeMultiplier === defaultFeesConfig.normal) {
      const fees =
        network.transactionFees.medianFeeMultiplier < network.transactionFees.minFeeMultiplier
          ? network.transactionFees.minFeeMultiplier
          : network.transactionFees.medianFeeMultiplier;
      return fees || network.defaultDynamicFeeMultiplier;
    }
    if (selectedFeeMultiplier === defaultFeesConfig.fast) {
      const fees =
        network.transactionFees.highestFeeMultiplier < network.transactionFees.minFeeMultiplier
          ? network.transactionFees.minFeeMultiplier
          : network.transactionFees.highestFeeMultiplier;
      return fees || network.defaultDynamicFeeMultiplier;
    }
    return 0;
  }

  /**
   * Decrypt message
   * @param current
   * @param network
   * @param transaction
   * @returns {Promise<void>}
   */
  public static decryptMessage = async (
    current: IAccountModel,
    network: INetworkModel,
    transaction: ITransferTransactionModel
  ): Promise<string> => {
    try {
      const transactionHash = transaction.hash || '';
      const repositoryFactory = new RepositoryFactoryHttp(network.node);
      const transactionHttp = repositoryFactory.createTransactionRepository();
      let tx: any;
      try {
        const resp: any = await transactionHttp.getTransaction(transactionHash, TransactionGroup.Confirmed).toPromise();
        tx = resp;
      } catch (e) {
        console.log(e);
      }
      if (!tx) {
        try {
          const resp: any = await transactionHttp
            .getTransaction(transactionHash, TransactionGroup.Unconfirmed)
            .toPromise();
          tx = resp;
        } catch (e) {
          console.log(e);
        }
      }
      if (tx && tx.message.type === 1) {
        const networkType = NetworkService.getNetworkTypeFromModel(network);
        const currentAccount = Account.createFromPrivateKey(current.privateKey, networkType);
        if (tx.recipientAddress.plain() === currentAccount.address.plain()) {
          return EncryptedMessage.decrypt(tx.message, current.privateKey, tx.signer).payload;
        } else {
          const accountHttp = repositoryFactory.createAccountRepository();

          const recipientAccountInfo: any = await accountHttp.getAccountInfo(tx.recipientAddress).toPromise();
          const recipientAccount = PublicAccount.createFromPublicKey(recipientAccountInfo.publicKey, networkType);
          return EncryptedMessage.decrypt(tx.message, current.privateKey, recipientAccount).payload;
        }
      } else {
        return '';
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  public static getTransaction = async (hash: string, network: INetworkModel): Promise<Transaction | undefined> => {
    const transactionHttp = new TransactionHttp(network.node);
    let tx;
    try {
      tx = await transactionHttp.getTransaction(hash, TransactionGroup.Confirmed).toPromise();
    } catch (e) {
      console.log(e);
    }
    if (!tx) {
      try {
        tx = await transactionHttp.getTransaction(hash, TransactionGroup.Unconfirmed).toPromise();
      } catch (e) {
        console.log(e);
      }
    }
    if (!tx) {
      try {
        tx = await transactionHttp.getTransaction(hash, TransactionGroup.Partial).toPromise();
      } catch (e) {
        console.log(e);
      }
    }
    return tx;
  };

  public static async socket(
    payload: {
      privateKey: string;
      name: string;
      onSuccess: (socket: ListenerService) => void;
      onError: (e: any, socket: ListenerService) => void;
    },
    selectedNetwork: INetworkModel,
    network: 'testnet' | 'mainnet'
  ): Promise<any> {
    const blockChain: ListenerService = new ListenerService(
      () => payload.onSuccess(blockChain),
      (err: any) => payload.onError(err, blockChain)
    );
    // TO DO set selected NODE
    let selectedNode: any = {};
    if (!selectedNode) {
      const network = getDefaultNetworkType();
      selectedNode = getNodes(network)[0];
    }
    const networkS: any = await NetworkService.getNetworkModelFromNode(selectedNode);
    blockChain.setNetwork(networkS);

    const selectedAccount = AccountService.createFromPrivateKey(payload.privateKey, payload.name, selectedNetwork.type);
    const rawAddress = AccountService.getAddressByAccountModelAndNetwork(selectedAccount, network);
    await blockChain.listen(rawAddress);
    return selectedAccount;
  }

  public static generateHush(data: any, type: 'car' | 'user'): string {
    let hush = '';
    if (['car', 'user'].includes(type)) {
      const obj = generateCleanData(data);
      console.log('target', '------------>>>>', JSON.stringify(obj));

      const normal = JSON.stringify(obj);
      console.log('payload :::', normal);
      hush = sha256(normal);
      console.log('hashSum --> payload :::', hush);
    }
    return hush;
  }
  private static async getMaxFee(selectedNetworkPayload: {
    payload: {
      messageText: string;
      messageEncrypted: boolean;
      fee: number;
      mosaics: IMosaicModel[];
      recipientAddress: string;
      type: string;
    };
    selectedNetwork: INetworkModel;
  }): Promise<number> {
    const { payload, selectedNetwork } = selectedNetworkPayload;
    try {
      const networkType: any = NetworkService.getNetworkTypeFromModel(selectedNetwork);
      const dummyAccount: any = Account.generateNewAccount(networkType);
      const transactionModel: any = {
        type: 'transfer',
        recipientAddress: dummyAccount.address.plain(),
        messageText: payload.messageText,
        messageEncrypted: payload.messageEncrypted,
        mosaics: payload.mosaics,
        fee: payload.fee,
      };

      const ttx = await TransactionService.transactionModelToTransactionObject(
        transactionModel,
        dummyAccount,
        selectedNetwork
      );
      const ttxWithFee = TransactionService.calculateMaxFee(ttx, selectedNetwork, transactionModel.fee);
      return ttxWithFee.maxFee.compact();
    } catch (e) {
      return 0;
    }
  }
  static createWallet = async (): Promise<any> => {
    const node: 'testnet' | 'mainnet' | any = env.REACT_APP_DEFAULT_NETWORK_TYPE || 'testnet';
    const nodes = getNodes(node);
    try {
      const network = await NetworkService.getNetworkModelFromNode(nodes[0]);
      return { network, account: AccountService.createAccount(network) };
    } catch {
      const network = await NetworkService.getNetworkModelFromNode(nodes[1]);
      return { network, account: AccountService.createAccount(network) };
    }
  };
  static async makeTransaction(
    recipientAddress: string,
    hashSum: string,
    ownedMosaics: any,
    selectedNetwork: INetworkModel,
    selectedAccount: IAccountModel
  ) {
    try {
      console.log('recipientAddress', recipientAddress);
      const mosaicName = NATIVE_MOSAIC_ID;
      const mosaic: any = _.cloneDeep(ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName));

      console.log('mosaic', mosaic);
      if (mosaic) {
        mosaic.amount = parseFloat('0') * Math.pow(10, mosaic?.divisibility);

        const transaction = {
          recipientAddress,
          mosaics: [mosaic] as IMosaicModel[],
          messageText: hashSum,
          messageEncrypted: false,
          type: 'transfer',
          fee: defaultFeesConfig.normal,
        };

        const maxFee: any = await this.getMaxFee({ payload: transaction, selectedNetwork });

        const trans: any = {
          ...transaction,
          mosaics: [mosaic],
          resolvedFee: maxFee / (mosaic?.divisibility || Math.pow(10, 6)),
          fee: maxFee,
        };
        const transactionRes = await TransactionService.signAndBroadcastTransactionModel(
          trans,
          selectedAccount,
          selectedNetwork
        );
        console.log('Transaction response: ', transactionRes);
      }
    } catch (e) {
      console.log(e);
    }
  }
}
