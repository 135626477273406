import React, { useState, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import { Search } from '../../../components/Search';
import { Link } from 'react-router-dom';
import { PageContenEnum } from '../../../utils/contstants';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { buildCarPartListColumns } from './columns';
import { CAR_PART_URL } from '../../../store/api';
import { ListTable } from '../../../components/ListTable';
import { ListDataProvider, useListDataProvider } from '../../../hooks/useListDataProvider';
import { Api } from '../../../utils/Api';
import { ICarPart } from '../../../types/car-parts';
import { usePartName } from './usePartName';
import { usePartSerialNumber } from './usePartSerialNumber';
import { usePartCategory } from './usePartCategory';
import { OrganizationSelect } from '../../../components/OrganizationSelect';
import { RolesTypeEnum } from '../../../utils/filterColumns';
import { useStore } from '../../../store';
import { EmptyPlaceholder } from 'components/EmptyPlaceholder';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'none',
      paddingBottom: 20,
    },
    paper: {
      background: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    button: {
      color: '#1554d5',
      marginTop: 16,
    },
  })
);

const carPartDataProvider: ListDataProvider<ICarPart> = async ({ organizationId, offset, limit, search, filter }) => {
  const response = await Api.get<{ data: ICarPart[]; total: number }>(CAR_PART_URL, {
    offset,
    limit,
    organizationId,
    ...(search ? { 'filter[search]': `%${search}%` } : {}),
    ...(filter.serial ? { 'filter[serial]': filter.serial } : {}),
    ...(filter.name ? { 'filter[name]': filter.name } : {}),
    ...(filter.model ? { 'filter[model][id]': filter.model } : {}),
    ...(filter.subCategory ? { 'filter[category][id]': filter.subCategory } : {}),
  });
  return response.data;
};

export const CarPartList: React.FC = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    state: { user },
  } = useStore();
  const listData = useListDataProvider(carPartDataProvider, {
    limit: 8,
  });

  const partNames = usePartName();
  const serialNumbers = usePartSerialNumber();
  const [categories, subCategories] = usePartCategory();

  const columns = useMemo(
    () => buildCarPartListColumns({ partNames, serialNumbers, categories, subCategories }),
    [partNames, serialNumbers, categories, subCategories]
  );

  return (
    <div>
      <Paper elevation={0} className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <Search
            placeholder="Search by name or code"
            onChange={(value) => {
              setSearchValue(value);
              listData.onSearch(value);
            }}
          />
          {user?.role === RolesTypeEnum.ADMIN && (
            <OrganizationSelect
              // organizationId={listData.query.organizationId}
              onChange={listData.onChangeOrganization}
            />
          )}
        </Paper>
        <Paper elevation={0} className={classes.paper}>
          <Link className={classes.link} to={`car-parts/${PageContenEnum.ADD}`}>
            <Button startIcon={<AddCircleOutlineIcon />} className={classes.button} variant="outlined" color="primary">
              Create
            </Button>
          </Link>
        </Paper>
      </Paper>
      {searchValue !== '' && listData.total === 0 ? (
        <EmptyPlaceholder />
      ) : (
        <ListTable
          columns={columns}
          loading={listData.loading}
          data={listData.data}
          total={listData.total}
          offset={listData.query.offset}
          limit={listData.query.limit}
          onPageChange={listData.onPageChange}
          onFilterChange={listData.onFilter}
        />
      )}
    </div>
  );
};
