import React, { useCallback, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import { Link, useHistory } from 'react-router-dom';
import { PageContenEnum } from '../../../utils/contstants';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DICTIONARY_URL, DICTIONARY_VALUE_URL } from '../../../store/api';
import { ListTable } from '../../../components/ListTable';
import { ListDataProvider, useListDataProvider } from '../../../hooks/useListDataProvider';
import { Api } from '../../../utils/Api';
import { IDictionary, IDictionaryValue } from '../../../types/dictionaries';
import { buildDictionaryValueListColumns } from './value-columns';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'none',
      paddingBottom: 20,
    },
    paper: {
      background: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    button: {
      color: '#1554d5',
      marginTop: 16,
    },
  })
);

const dictionaryValueDataProvider: ListDataProvider<IDictionaryValue> = async ({ id }) => {
  const response = await Api.get<IDictionary>(DICTIONARY_URL + '/' + id);
  return {
    total: response.data.values.length,
    data: response.data.values.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }),
  };
};

export const DictionaryValueList: React.FC<{ id: string }> = ({ id }) => {
  const classes = useStyles();
  const listData = useListDataProvider(dictionaryValueDataProvider, {
    id,
    limit: 10,
  });
  const { goBack } = useHistory();

  console.log({ listData });

  const onDelete = useCallback(async (id: string) => {
    if (confirm('Are you sure you want to delete this item?')) {
      await Api.delete<IDictionary>(DICTIONARY_VALUE_URL + '/' + id);
      listData.reload();
    }
  }, []);

  const columns = useMemo(() => buildDictionaryValueListColumns({ onDelete }), [onDelete]);

  return (
    <div>
      <Paper elevation={0} className={classes.root}>
        <Button startIcon={<KeyboardBackspaceIcon />} onClick={() => goBack()}>
          Back
        </Button>
        <Typography variant="h6" gutterBottom>
          Dictionary items
        </Typography>
      </Paper>
      <Paper elevation={0} className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <Link className={classes.link} to={`/dictionaries/${PageContenEnum.ADD}/${id}`}>
            <Button startIcon={<AddCircleOutlineIcon />} className={classes.button} variant="outlined" color="primary">
              Create
            </Button>
          </Link>
        </Paper>
      </Paper>
      <ListTable
        columns={columns}
        loading={listData.loading}
        data={listData.data}
        total={listData.total}
        offset={listData.query.offset}
        limit={listData.total}
        onPageChange={listData.onPageChange}
        onFilterChange={listData.onFilter}
      />
    </div>
  );
};
