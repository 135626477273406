import React from 'react';
import { DataGrid, GridColumns, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ListDataSortQuery } from '../../hooks/useListDataProvider';
import { filterToQueryString } from 'utils/mui-data-grid';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 740,
  },
  container: {
    maxHeight: 740,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 150,
  },
  row: {
    whiteSpace: 'nowrap',
  },
});

interface IMuiTableProps {
  columns: GridColumns;
  data: any;
  total: number;
  limit: number;
  offset: number;
  onPageChange: (offset: number, limit: number) => void;
  onFilterChange: (filter: Record<string, string>) => void;
  onSortChange?: (sort: ListDataSortQuery | null) => void;
  loading?: boolean;
}

export const ListTable = ({
  columns,
  data,
  total,
  loading,
  onPageChange,
  onFilterChange,
  onSortChange,
  offset,
  limit,
}: IMuiTableProps) => {
  const classes = useStyles();

  const handleChangePage = (newPage: number) => {
    onPageChange(newPage * limit, limit);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    onPageChange(offset, pageSize);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    const filter = model.items.reduce((filter, { columnField, operatorValue, value }) => {
      if (columnField) {
        const qs = filterToQueryString(operatorValue, value);

        if (qs !== null) {
          filter[columnField] = qs;
        }
      }

      return filter;
    }, {} as Record<string, string>);
    onFilterChange(filter);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (onSortChange) {
      const [firstSortItem] = model;
      onSortChange(
        firstSortItem
          ? {
              field: firstSortItem.field,
              sort: (firstSortItem.sort as string).toUpperCase() as ListDataSortQuery['sort'],
            }
          : null
      );
    }
  };

  return (
    <Paper className={classes.root} style={{ height: limit * 52 + 150 }}>
      <DataGrid
        rows={data}
        rowCount={total}
        columns={columns}
        pageSize={limit}
        loading={loading}
        rowsPerPageOptions={[8, 25, 100]}
        disableSelectionOnClick
        onPageChange={handleChangePage}
        pagination
        paginationMode={'server'}
        page={offset === 0 ? 0 : offset / limit}
        onPageSizeChange={handleChangeRowsPerPage}
        onFilterModelChange={handleFilterModelChange}
        filterMode={'server'}
        sortingMode={'server'}
        onSortModelChange={handleSortModelChange}
      />
    </Paper>
  );
};
