import { timeout } from 'rxjs/operators';
import {
  ChainHttp,
  NetworkConfiguration,
  NetworkHttp,
  NetworkType,
  NodeHttp,
  RepositoryFactoryHttp,
  TransactionFees,
} from 'symbol-sdk';

import { durationStringToSeconds } from '../../utils/commonHelpers';

import { INetworkModel } from '../../types/models/NetworkModel';
import { getNodes } from './environment';
import { env } from 'store/env';

const REQUEST_TIMEOUT = 50000;

export default class NetworkService {
  public static async getNetwork(): Promise<any> {
    const node: 'testnet' | 'mainnet' | any = env.REACT_APP_DEFAULT_NETWORK_TYPE || 'testnet';
    if (typeof window !== 'undefined') {
      const nodes = getNodes(node);
      try {
        return await NetworkService.getNetworkModelFromNode(nodes[0]);
      } catch {
        return await NetworkService.getNetworkModelFromNode(nodes[1]);
      }
    }
  }
  /**
   * Get network model from node
   * @param node
   * @returns {Promise<{node: string, generationHash: string, type: NetworkType, currencyMosaicId: string}>}
   */
  public static async getNetworkModelFromNode(node: string): Promise<any> {
    const networkHttp: any = new NetworkHttp(node);
    const chainHttp: any = new ChainHttp(node);
    try {
      const [networkType, networkProps, chainInfo]: any = await Promise.all([
        networkHttp.getNetworkType().pipe(timeout(REQUEST_TIMEOUT)).toPromise(),
        networkHttp.getNetworkProperties().pipe(timeout(REQUEST_TIMEOUT)).toPromise(),
        chainHttp.getChainInfo().pipe(timeout(REQUEST_TIMEOUT)).toPromise(),
      ]);

      const generationHash: any = networkProps.network.generationHashSeed;
      const _networkType: any = networkType;
      const networkCurrency: any = await new RepositoryFactoryHttp(node, {
        networkType: _networkType,
        generationHash,
      })
        .getCurrencies()
        .toPromise();

      let transactionFees: TransactionFees | any;
      try {
        const resp: any = await networkHttp.getTransactionFees().pipe(timeout(REQUEST_TIMEOUT)).toPromise();
        transactionFees = resp;
      } catch (e) {
        transactionFees = new TransactionFees(0, 0, 0, 0, 0);
      }

      const currencyDivisibility: any = networkCurrency.currency.divisibility;

      return {
        type: networkType === NetworkType.TEST_NET ? 'testnet' : 'mainnet',
        generationHash: networkProps.network.generationHashSeed || '',
        node: node,
        currencyMosaicId: networkProps.chain.currencyMosaicId?.replace('0x', '')?.replace(/'/g, '') || '',
        currencyDivisibility,
        chainHeight: chainInfo.height.compact(),
        blockGenerationTargetTime: this._blockGenerationTargetTime(networkProps),
        epochAdjustment: parseInt(networkProps.network.epochAdjustment || ''),
        transactionFees: transactionFees,
        defaultDynamicFeeMultiplier: parseInt(networkProps.chain.defaultDynamicFeeMultiplier || '1000'),
      };
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Block generation time
   * @param networkConfiguration
   * @param defaultValue
   * @returns {number}
   * @private
   */
  private static _blockGenerationTargetTime(
    networkConfiguration: NetworkConfiguration | undefined,
    defaultValue: number | undefined = 15
  ): number {
    return (
      (networkConfiguration &&
        networkConfiguration.chain &&
        durationStringToSeconds(networkConfiguration.chain.blockGenerationTargetTime || '')) ||
      defaultValue
    );
  }

  /**
   * Return network type from model
   * @param network
   * @returns {NetworkType.MAIN_NET|NetworkType.MIJIN|NetworkType.TEST_NET}
   */
  public static getNetworkTypeFromModel(network: INetworkModel): NetworkType {
    switch (network.type) {
      case 'mainnet':
        return NetworkType.MAIN_NET;
      case 'testnet':
        return NetworkType.TEST_NET;
      default:
        return 0;
    }
  }

  /**
   * Check if network or node are up
   * @param network
   */
  public static async isNetworkUp(network: INetworkModel): Promise<boolean> {
    const nodeHttp = new NodeHttp(network.node);
    try {
      const health: any = await nodeHttp.getNodeHealth().toPromise();
      return health.apiNode === 'up' && health.db === 'up';
    } catch {
      return false;
    }
  }
}
