import { ReactNode } from 'react';

import { SignIn } from 'pages';
import { useStore } from 'store';
import { LOCAL_STORAGE_JWT_TOKEN_KEY } from 'store/contstants';

type Props = {
  children: ReactNode;
};

export const Autorize = ({ children }: Props): any => {
  const {
    state: { isLoggedIn },
  } = useStore();
  const hasAuth = !!localStorage.getItem(LOCAL_STORAGE_JWT_TOKEN_KEY);
  return isLoggedIn || hasAuth ? children : <SignIn />;
};
