import { createRestDataSource } from 'components/DynamicForm';
import { MAINTENANCE_URL } from 'store/api';
import { dateTimeString } from 'components/DetailsView/config/helpers';

export const MAINTENANCE_DETAILS_CONFIG = {
  dataSource: createRestDataSource(MAINTENANCE_URL),
  mapSourceToValues: (data: any) => ({
    status: data?.status?.name,
    name: data?.name,
    event: data?.event?.name,
    startDate: dateTimeString(data?.startDate).getDate(),
    dueDate: dateTimeString(data?.dueDate).getDate(),
    id: data?.id,
    code: data?.code,
    creator: data?.creator?.fullName,
    createdAt: dateTimeString(data?.createdAt).getDate(),
    updatedAt: dateTimeString(data?.updatedAt).getDate(),
  }),
  fields: [
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'event',
      label: 'Event',
    },
    {
      name: 'startDate',
      label: 'Start date',
    },
    {
      name: 'dueDate',
      label: 'Due date',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'creator',
      label: 'Creator',
    },
    {
      name: 'createdAt',
      label: 'Created',
    },
    {
      name: 'updatedAt',
      label: 'Updated',
    },
  ],
};
