import { createRestDataSource, dicToOptionMapper, formatIsoString, FormConfig } from 'components/DynamicForm';
import { EventDetails } from 'types/events';
import { EVENTS_URL } from 'store/api';
import * as Yup from 'yup';
import { RootState } from '../../store/state';

export type EventFormValues = {
  name: string;
  trackName: string;
  dateStart: string;
  dateEnd: string;
  typeId?: string;
  championshipId?: string;
  maintenances: string[];
  organizationId?: string;
};

export const EVENT_FORM_CONFIG: FormConfig<EventDetails, EventFormValues> = {
  dataSource: createRestDataSource(EVENTS_URL),
  mapSourceToValues: (data) => ({
    name: data.name,
    trackName: data.trackName,
    dateStart: formatIsoString(data.dateStart),
    dateEnd: formatIsoString(data.dateEnd),
    typeId: data.type?.id,
    championshipId: data.championship?.id,
    maintenances: data.maintenances?.map(({ id }) => id),
  }),
  defaultValues: {
    name: '',
    trackName: '',
    dateStart: '',
    dateEnd: '',
    typeId: '',
    championshipId: '',
    maintenances: [],
  },
  fields: [
    {
      name: 'name',
      type: 'textField',
      required: true,
      label: 'Name',
      validation: Yup.string()
        .required('Please enter the event name.')
        .min(1, 'Event name must be between 6 and 256 characters.')
        .max(100, 'Event name must be between 6 and 256 characters.'),
    },
    {
      name: 'trackName',
      label: 'Track',
      type: 'textField',
      validation: Yup.string().max(100, 'Track name must be between 1 and 256 characters.'),
    },
    {
      name: 'dateStart',
      label: 'Start date',
      type: 'textField',
      inputType: 'datetime-local',
      required: true,
      validation: Yup.string().required('Please select start date.'),
    },
    {
      name: 'dateEnd',
      label: 'End date',
      type: 'textField',
      inputType: 'datetime-local',
      required: true,
      validation: Yup.string()
        .required('Please select end date.')
        .test(
          'is-greater-than-start',
          'End date should be greater than start date.',
          (value, context) => new Date(context.parent.dateStart) < new Date(context.parent.dateEnd)
        ),
    },
    {
      name: 'typeId',
      label: 'Type',
      type: 'select',
      required: true,
      optionsSelector: ({ eventTypeDictionary: t }) => t.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'championshipId',
      label: 'Championship',
      type: 'select',
      optionsSelector: ({ eventChampionshipDictionary: c }) => c.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'organizationId',
      label: 'Organization',
      type: 'select',
      isDisabled: ({ user }: RootState, mode) => user?.role !== 'admin' || mode === 'edit',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
      validation: Yup.string().uuid().required('Please select Organization.'),
    },
  ],
};
