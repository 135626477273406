import { createRestDataSource, dicToOptionMapper, formatIsoString, FormConfig } from 'components/DynamicForm';
import { ITask } from 'types/tasks';
import { TASKS_URL } from 'store/api';
import * as Yup from 'yup';
import { RootState } from '../../store/state';

export type TaskFormValues = {
  maintenanceId: string;
  typeId: string;
  name: string;
  assigneeId: string;
  dueDate: string;
  description: string;
  organizationId?: string;
};

export const TASK_FORM_CONFIG: FormConfig<ITask, TaskFormValues> = {
  dataSource: createRestDataSource(TASKS_URL),
  mapSourceToValues: (data) => ({
    maintenanceId: data.maintenance?.id,
    typeId: data.type?.id,
    dueDate: formatIsoString(data.dueDate),
    name: data.name,
    assigneeId: data.assignee?.id,
    description: data.description,
  }),
  defaultValues: {
    name: '',
    maintenanceId: '',
    dueDate: '',
    typeId: '',
    assigneeId: '',
    description: '',
  },
  fields: [
    {
      name: 'maintenanceId',
      type: 'select',
      label: 'Maintenance',
      optionsSelector: ({ maintenances }) => maintenances.data.map((item) => dicToOptionMapper(item, true)),
    },
    {
      name: 'typeId',
      label: 'Type',
      type: 'select',
      optionsSelector: ({ taskTypeDictionary }) => taskTypeDictionary.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'name',
      label: 'Name',
      type: 'textField',
      required: true,
      validation: Yup.string()
        .required('Please enter the name of the task.')
        .max(100, 'The name must be less than 100 characters'),
    },
    {
      name: 'assigneeId',
      label: 'Assignee',
      type: 'select',
      optionsSelector: ({ users }) => users.data.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'dueDate',
      label: 'Due date',
      type: 'textField',
      inputType: 'datetime-local',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      validation: Yup.string().max(4000, 'The description of the task must be less than 4000 characters').nullable(),
    },
    {
      name: 'organizationId',
      label: 'Organization',
      type: 'select',
      isDisabled: ({ user }: RootState, mode) => user?.role !== 'admin' || mode === 'edit',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
      validation: Yup.string().uuid().required('Please select Organization.'),
    },
  ],
};
