import { dateFormatter, linkFormatter, linkViewFormatter } from '../../../utils/columns';
import { GridColumns, GridFilterInputValue, GridFilterItem } from '@mui/x-data-grid';

type Options = {
  partNames: string[];
  serialNumbers: string[];
  categories: { value: string; label: string }[];
  subCategories: { value: string; label: string }[];
};
const filterOperators = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (filterItem.value == null || filterItem.value === '') {
        return null;
      }
      return ({ value }: any): boolean => {
        if (typeof value === 'object') {
          return filterItem.value === (value as { value: any; label: string }).value;
        }
        return filterItem.value === value;
      };
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: 'singleSelect' },
  },
];

export const buildCarPartListColumns = (options: Options): GridColumns => [
  {
    field: 'code',
    headerName: 'Code',
    renderCell: ({ row }) => linkFormatter(row.code, 'car-parts', row.id),
    width: 200,
    sortable: false,
    filterable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    filterable: true,
    type: 'singleSelect',
    filterOperators,
    valueOptions: options.partNames.map((partName) => ({
      value: partName,
      label: partName,
    })),
  },
  {
    field: 'serial',
    headerName: 'Serial number',
    width: 200,
    sortable: false,
    type: 'singleSelect',
    filterable: true,
    filterOperators,
    valueOptions: options.serialNumbers.map((serialNumber) => ({
      value: serialNumber,
      label: serialNumber,
    })),
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 90,
    type: 'number',
    sortable: false,
    filterable: false,
  },
  {
    width: 200,
    field: 'category',
    headerName: 'Category',
    type: 'singleSelect',
    sortable: false,
    filterable: false,
    filterOperators,
    renderCell: ({ row }) => row?.category?.parent?.name ?? <>-</>,
  },
  {
    width: 200,
    field: 'subCategory',
    headerName: 'Sub Category',
    type: 'singleSelect',
    sortable: false,
    filterable: true,
    filterOperators,
    valueOptions: options.subCategories,
    renderCell: ({ row }) => row?.category?.name,
  },
  {
    width: 200,
    field: 'mileageCurrentTime',
    filterable: false,
    sortable: false,
    headerName: 'Mileage Time',
  },
  {
    width: 200,
    field: 'mileageCurrentDistance',
    filterable: false,
    sortable: false,
    headerName: 'Mileage Distance',
  },
  {
    width: 200,
    field: 'createdAt',
    filterable: false,
    sortable: false,
    headerName: 'Created',
    renderCell: ({ row }) => dateFormatter(row?.createdAt),
  },
  {
    width: 200,
    field: 'status',
    sortable: false,
    headerName: 'Status',
    filterable: false,
    renderCell: ({ row }) => <>{row?.status?.name}</>,
  },
  {
    width: 200,
    field: 'allocation',
    filterable: false,
    sortable: false,
    headerName: 'Allocation',
    renderCell: ({ row }) => {
      switch (row?.allocation?.type) {
        case 'car_part':
          return linkFormatter(row?.allocation?.targetCarPart?.code, 'car-parts', row?.allocation?.targetCarPart?.id);
        case 'stock':
          return row?.allocation?.targetStock?.name;
        case 'car':
          return linkFormatter(row?.allocation?.targetCar?.code, 'cars', row?.allocation?.targetCar?.id);
      }
    },
  },
  {
    width: 100,
    field: '_view',
    filterable: false,
    sortable: false,
    headerName: 'View',
    renderCell: ({ row }) => linkViewFormatter(row.id, 'car-parts'),
  },
  {
    width: 100,
    field: '_edit',
    filterable: false,
    sortable: false,
    headerName: 'Edit',
    renderCell: ({ row }) => linkViewFormatter(row.id, 'car-parts', true),
  },
];
