import { createRestDataSource } from 'components/DynamicForm';
import { USER_URL } from 'store/api';

export const USER_DETAILS_CONFIG = {
  dataSource: createRestDataSource(USER_URL),
  mapSourceToValues: (data: any) => ({
    id: data?.id,
    fullName: data?.fullName,
    username: data?.username,
    organization: data?.organization?.name,
    role: data?.role,
  }),
  fields: [
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'fullName',
      label: 'Full name',
    },
    {
      name: 'username',
      label: 'Nick name',
    },
    {
      name: 'organization',
      label: 'Organization',
    },
    {
      name: 'role',
      label: 'Role',
    },
  ],
};
