import { createRestDataSource, dicToOptionMapper, formatIsoString, FormConfig } from 'components/DynamicForm';
import { EVENTS_URL } from 'store/api';
import { dateTimeString } from 'components/DetailsView/config/helpers';

export const EVENT_DETAILS_CONFIG = {
  dataSource: createRestDataSource(EVENTS_URL),
  mapSourceToValues: (data: any) => ({
    status: data?.status?.name,
    name: data?.name,
    type: data?.type?.name,
    dateStart: dateTimeString(data?.dateStart).getDate(),
    dateEnd: dateTimeString(data?.dateEnd).getDate(),
    championship: data?.championship?.name,
    trackName: data?.trackName,
    id: data?.id,
    code: data?.code,
    fullName: data?.user?.fullName,
    createdAt: dateTimeString(data?.createdAt).getDate(),
    updatedAt: dateTimeString(data?.updatedAt).getDate(),
  }),
  fields: [
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'dateStart',
      label: 'Start date',
    },
    {
      name: 'dateEnd',
      label: 'End date',
    },
    {
      name: 'championship',
      label: 'Championship',
    },
    {
      name: 'trackName',
      label: 'Track',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'fullName',
      label: 'Creater',
    },
    {
      name: 'createdAt',
      label: 'Created',
    },
    {
      name: 'updatedAt',
      label: 'Updated',
    },
  ],
};
