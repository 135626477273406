import React from 'react';
import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { CAR_PART_URL } from 'store/api';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { CAR_PART_FORM_CONFIG } from './car-part-form.config';
import { CAR_PART_DETAILS_CONFIG } from './car-part-details.config';
import { CarPartList } from './CarPartList/CarPartList';

export const CarParts = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <CarPartList />;
  } else if (content === PageContenEnum.SHOW) {
    return <ShowSection url={CAR_PART_URL} config={CAR_PART_DETAILS_CONFIG} id={id} title="Car Parts Details" />;
  } else if (content === PageContenEnum.EDIT) {
    return (
      <EditSection
        id={id}
        url={CAR_PART_URL}
        title="Edit Car Part"
        config={CAR_PART_FORM_CONFIG}
        wide={true}
        dataType="car-part"
      />
    );
  }

  return (
    <AddSection
      title="Create Car Part"
      config={CAR_PART_FORM_CONFIG}
      wide={true}
      formTitles={['Main Info:', '', 'Mileage Info:']}
      dataType="car-part"
    />
  );
};
