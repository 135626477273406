import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'overmind-react';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { Autorize } from 'utils/autorize';
import { store } from 'store';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';

import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from 'redux/configureStore';
import { ErrorBoundary } from 'components/ErrorBoundary';

const initialState = {};
const reduxStore = configureStore(initialState);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <ReduxProvider store={reduxStore}>
      <Provider value={store}>
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Autorize>
            <App />
          </Autorize>
        </Router>
      </Provider>
    </ReduxProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
