import React from 'react';
import { TextField } from '@material-ui/core';
import { FieldComponentProps } from './types';
import { TextFieldConfig } from '../config';

export const TextFormControl: React.FC<FieldComponentProps<TextFieldConfig>> = ({
  field,
  value,
  error,
  onChange,
  onBlur,
}) => {
  const handleChange = (value: string) => {
    switch (field.inputType) {
      case 'number':
        return onChange(Number(value));
      default:
        return onChange(value);
    }
  };
  return (
    <TextField
      value={value}
      type={field.inputType}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={onBlur}
      required={field.required}
      id={field.name as string}
      name={field.name as string}
      label={field.label}
      fullWidth
      variant="standard"
      error={!!error}
      helperText={error}
      InputLabelProps={{ shrink: true }}
    />
  );
};
