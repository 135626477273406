import { BaseValues, DataSource, FormConfig, SelectFieldOption } from './types';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { Api } from '../../../utils/Api';
import { IDictionaryValue } from '../../../types/dictionaries';
import { RootState } from '../../../store/state';
import { ListDataProvider } from '../../../hooks/useListDataProvider';

export const formatIsoString = (isoDateString?: string) => {
  if (!isoDateString) return '';
  const [dateTime] = isoDateString.split('.');
  return dateTime;
};

export const dictionaryToOptionMapper = ({ id, name }: IDictionaryValue): SelectFieldOption => ({
  value: id,
  label: name,
});

export const dicToOptionMapper = ({ id, name, fullName, code }: any, needCode?: boolean): SelectFieldOption => ({
  value: id,
  label: needCode ? `${code} ${name}` : name || fullName,
});

export const useConfigValidationSchema = <S extends BaseValues, V extends BaseValues>(
  config: FormConfig<S, V>,
  mode: 'edit' | 'create',
  state: RootState
) => {
  return useMemo(() => {
    return Yup.object().shape(
      config?.fields
        .filter(({ isDisabled }) => !(isDisabled && isDisabled(state, mode)))
        .reduce((acc, item) => {
          if (item.validation) acc[item.name] = item.validation;
          else if (item.getValidation) acc[item.name] = item.getValidation(mode);
          return acc;
        }, {} as any)
    );
  }, [config, mode, state]);
};

export const createRestDataSource = <Source, FormValues>(baseUrl: string): DataSource<Source, FormValues> => ({
  get: (id) => Api.get<Source>(`${baseUrl}/${id}`).then(({ data }) => data),
  update: (id, values) => Api.put(`${baseUrl}/${id}`, values),
  create: (values) => Api.post(`${baseUrl}`, values),
  delete: (id) => Api.delete(`${baseUrl}/${id}`),
});

export const dataProvider =
  (url: string): ListDataProvider<any> =>
  async ({ offset, limit, organizationId, search, filter }) => {
    const response = await Api.get<{ data: any[]; total: number }>(url, {
      offset,
      limit,
      organizationId,
      ...(search ? { 'filter[search]': `%${search}%` } : {}),
      ...(filter.type ? { 'filter[type][id]': filter.type } : {}),
      ...(filter.championship ? { 'filter[championship][id]': filter.championship } : {}),
      ...(filter.trackName ? { 'filter[trackName]': filter.trackName } : {}),
      ...(filter.status ? { 'filter[status][id]': filter.status } : {}),
    });
    return response.data;
  };
