import { linkViewFormatter } from '../../../utils/columns';
import { GridColumns } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

type Options = {
  onDelete: (id: string) => any;
};
export const buildDictionaryValueListColumns = ({ onDelete }: Options): GridColumns => [
  {
    field: 'alias',
    headerName: 'alias',
    width: 280,
    sortable: false,
    filterable: false,
  },
  {
    field: '_color',
    headerName: 'Name (with colors)',
    width: 280,
    sortable: false,
    filterable: false,
    type: 'string',
    renderCell: ({ row: { backgroundColor, color, name } }) => (
      <div
        style={{
          color,
          backgroundColor,
          padding: '0 8px',
        }}>
        {name}
      </div>
    ),
  },
  {
    width: 100,
    field: '_view',
    filterable: false,
    sortable: false,
    headerName: 'Edit',
    renderCell: ({ row }) => linkViewFormatter(row.id, '/dictionaries', true),
  },
  {
    width: 100,
    field: '_delete',
    filterable: false,
    sortable: false,
    headerName: 'Delete',
    renderCell: ({ row }) => (
      <IconButton onClick={() => onDelete(row.id)}>
        <DeleteIcon />
      </IconButton>
    ),
  },
];
