import { createRestDataSource } from 'components/DynamicForm';
import { TASKS_URL } from 'store/api';
import { dateTimeString } from 'components/DetailsView/config/helpers';

export const TASKS_DETAILS_CONFIG = {
  dataSource: createRestDataSource(TASKS_URL),
  mapSourceToValues: (data: any) => ({
    status: data?.status?.name,
    name: data?.name,
    type: data?.type?.name,
    dueDate: data?.dueDate,
    description: data?.description,
    id: data?.id,
    code: data?.code,
    createdAt: dateTimeString(data?.createdAt).getDate(),
    updatedAt: dateTimeString(data?.updatedAt).getDate(),
    assignee: data?.assignee?.fullName,
  }),
  fields: [
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'name',
      label: 'name',
    },
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'assignee',
      label: 'Assignee',
    },
    {
      name: 'dueDate',
      label: 'Due date',
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'createdAt',
      label: 'Created',
    },
    {
      name: 'updatedAt',
      label: 'Updated',
    },
  ],
};
