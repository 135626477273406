import { useEffect, useState } from 'react';
import { Api } from '../../../utils/Api';
import { CAR_PART_SERIAL_NUMBER_URL } from '../../../store/api';

const limit = 200;
export const usePartSerialNumber = (): string[] => {
  const [offset, setOffset] = useState<number>(0);
  const [partNames, setPartNames] = useState<string[]>([]);
  useEffect(() => {
    Api.get<{ data: string[]; total: number }>(CAR_PART_SERIAL_NUMBER_URL, {
      limit,
      offset,
    }).then((response) => {
      setPartNames((items) => items.concat(response.data.data));
      if (offset < response.data.total - limit) {
        setOffset(offset + limit);
      }
    });
  }, [offset]);
  return partNames;
};
