import React, { useState, useMemo, useEffect } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useFetchOnce } from 'hooks/useFetchOnce';
import { useStore } from 'store';
import { DictionaryValuesStateKeys } from '../../store/state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 140,
      marginRight: 20,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export type ISelectType = {
  label: string;
  value: string;
};

interface IFilterMenu {
  url?: string;
  filterKey: string;
  title: string;
  customData?: ISelectType[];
  dictionaryName?: DictionaryValuesStateKeys;
  onChange: (filterKey: string, value: unknown) => void;
}

export const FilterMenu = ({ url, customData, title, filterKey, onChange, dictionaryName }: IFilterMenu) => {
  const classes = useStyles();
  const { state } = useStore();
  const [age, setAge] = useState('');

  const [res, fetchData] = useFetchOnce([], url || '');

  useEffect(() => {
    fetchData({ offset: 0, limit: 100 });
  }, [fetchData]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
    onChange(filterKey, event.target.value);
  };

  const menuList = useMemo(() => {
    if (!url && !customData && !dictionaryName) return null;
    let menuData = null;

    if (url) {
      menuData = res?.data?.map((item: any) => ({
        value: item.id,
        label: `${item.code || ''} ${item.name || item.fullName}`,
      }));
    } else if (customData) {
      menuData = customData;
    } else {
      menuData =
        dictionaryName &&
        state[dictionaryName]?.map((item: any) => ({
          value: item.id,
          label: `${item.code || ''} ${item.name || item.fullName}`,
        }));
    }

    return menuData?.map((item: any) => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  }, [res, url, customData, dictionaryName, state]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={age}
        onChange={handleChange}
        label="Age"
        autoWidth>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {menuList}
      </Select>
    </FormControl>
  );
};
