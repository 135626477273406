import { QueryPrefixModifier } from './contstants';
import { isNil, isEmpty } from 'lodash';

export const filterToQueryString = (operatorValue: string | undefined, value: any): string | null => {
  if (operatorValue === undefined || operatorValue === 'is') {
    return value;
  }

  let prefix = QueryPrefixModifier.Equals;
  let param = value;
  let isInputRequired = true;

  switch (operatorValue) {
    case 'equals':
      prefix = QueryPrefixModifier.Equals;
      break;
    case 'contains':
      prefix = QueryPrefixModifier.Contains;
      break;
    case 'startsWith':
      prefix = QueryPrefixModifier.StartsWith;
      break;
    case 'endsWith':
      prefix = QueryPrefixModifier.EndsWith;
      break;
    case 'isEmpty':
      prefix = QueryPrefixModifier.IsEmpty;
      param = '';
      isInputRequired = false;
      break;
    case 'isNotEmpty':
      prefix = QueryPrefixModifier.IsNotEmpty;
      param = '';
      isInputRequired = false;
      break;
  }

  if (isInputRequired && (isNil(value) || isEmpty(value))) {
    return null;
  }

  return `${prefix}|${param}`;
};
