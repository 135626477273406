import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { createStyleHelper } from 'utils/helpers';
import LoadingBar from 'react-top-loading-bar';
import LogoIcon from 'assets/png/vrm-logo.svg';
import { routes } from 'utils/routes';
import { showContentTitles, addEditContentTitles, PageContenEnum } from 'utils/contstants';
import { useStore } from 'store';
import { isLocalhost } from 'utils/helpers';
import { RoutesComponent } from './Routes';

const useStyles = makeStyles(createStyleHelper);

const App = () => {
  const classes: any = useStyles();
  const { push } = useHistory();
  const {
    state: { isFetching, user },
    actions: {
      logout,
      getCars,
      getMarks,
      getUsers,
      getModels,
      getEvents,
      getAccount,
      getDictionaries,
      getMaintenances,
      getOrganizations,
    },
  } = useStore();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);

  const loaderRef: any = useRef(null);

  useEffect(() => {
    if (isFetching) {
      loaderRef?.current?.continuousStart();
    } else {
      loaderRef?.current?.complete();
    }
  }, [isFetching, loaderRef]);

  useEffect(() => {
    getCars();
    getUsers();
    getMarks();
    getModels();
    getEvents();
    getAccount();
    getMaintenances();
    getDictionaries();
    getOrganizations();
  }, [getDictionaries, getEvents, getCars, getMaintenances, getOrganizations, getMarks, getModels]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const headerTitle = useMemo(() => {
    const route = pathname.split('/')?.[1];
    if (pathname.includes(PageContenEnum.SHOW)) {
      return showContentTitles[route];
    } else if (pathname.includes(PageContenEnum.EDIT)) {
      return `Edit ${addEditContentTitles[route]}`;
    } else if (pathname.includes(PageContenEnum.ADD)) {
      return `Create ${addEditContentTitles[route]}`;
    } else {
      return route;
    }
  }, [pathname]);

  const routesForRole = useMemo(() => {
    return routes.filter((route) => route.access.includes(user?.role) || isLocalhost());
  }, [user?.role]);

  return (
    <div className={classes.root}>
      <LoadingBar color="#07bc0c" height={4} ref={loaderRef} />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {headerTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <img src={LogoIcon} alt="logo" className={classes.logo} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {routesForRole.map(({ name, Icon, path }, key) => (
            <ListItem selected={path === pathname} key={key} button onClick={() => push(path)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List className={classes.list}>
          <ListItem
            button
            onClick={() => {
              logout();
            }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <RoutesComponent key={pathname} />
        </Container>
      </main>
    </div>
  );
};

export default App;
