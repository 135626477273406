import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useStore } from 'store';
import { RolesTypeEnum } from 'utils/filterColumns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 300,
      marginRight: theme.spacing(2),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

type ISearchProps = {
  onChange: (value: string) => void;
  placeholder?: string;
};

export const Search = ({ onChange, placeholder = 'Search by name or code' }: ISearchProps) => {
  const classes = useStyles();
  const {
    state: { user },
  } = useStore();

  return (
    <Paper className={classes.root}>
      <IconButton disableTouchRipple className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={(e) => onChange(e.target.value)}
      />
    </Paper>
  );
};
// Search by full name or username
