export const API_PATH = {
  AUTH: {
    SIGN_IN: {
      _: '/auth/admin/signin',
    },
  },
  USER: {
    _: '/user',
    BY_ID: '/user/:id',
  },
  EVENT: {
    _: '',
    BY_ID: '/event/:id',
  },
} as const;
