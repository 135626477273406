import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { flushAccessToken } from 'store/actions';
import { isObject } from 'lodash';
import { AUTH_REFRESH_URL, BASE_URL } from 'store/api';
import { JWTToken } from 'types/common';
import { LOCAL_STORAGE_JWT_TOKEN_KEY } from 'store/contstants';

export class Api {
  static instance: Api;

  axiosInstance: AxiosInstance;
  jwtToken: JWTToken | null = null;

  constructor() {
    this.axiosInstance = axios.create({
      timeout: 300000,
      baseURL: BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    this.setTokenInterceptors(this.axiosInstance);
  }

  private async refreshJWTToken(): Promise<string | null> {
    const token = this.getJWTToken();

    if (!token) {
      flushAccessToken();

      return null;
    }

    const request = await axios.request({
      headers: {
        'Content-Type': 'application/json',
        Accept: '*',
        Authorization: `Bearer ${token.refreshToken}`,
      },
      url: AUTH_REFRESH_URL,
      method: 'POST',
    });

    const { status, data } = request;

    if ([200, 201].includes(status) && isObject(data)) {
      return this.setJWTToken(data as JWTToken).accessToken;
    }

    return null;
  }

  private setJWTToken(jwtToken: JWTToken): JWTToken {
    if (isObject(jwtToken)) {
      localStorage.setItem(LOCAL_STORAGE_JWT_TOKEN_KEY, JSON.stringify(jwtToken));
      this.jwtToken = jwtToken;
    }

    return jwtToken;
  }

  private getJWTToken(): JWTToken | null {
    try {
      if (!this.jwtToken) {
        const data: any = localStorage.getItem(LOCAL_STORAGE_JWT_TOKEN_KEY);

        this.jwtToken = JSON.parse(data);
      }

      return this.jwtToken;
    } catch {
      return null;
    }
  }

  private isAccessTokenValid(): boolean {
    const token = this.getJWTToken();

    return token ? token.accessTokenExpiresIn * 1000 > Date.now() : false;
  }

  private isRefreshTokenValid(): boolean {
    const token = this.getJWTToken();

    return token ? token.refreshTokenExpiresIn * 1000 > Date.now() : false;
  }

  private setTokenInterceptors(instance: AxiosInstance): void {
    instance.interceptors.request.use(
      async (config: any) => {
        const jwtToken = this.getJWTToken();
        let token: string | null = null;

        if (!jwtToken) {
          return config;
        }

        const isAccessTokenValid = this.isAccessTokenValid();
        const isRefreshTokenValid = this.isRefreshTokenValid();

        if (isAccessTokenValid) {
          token = jwtToken.accessToken;
        } else if (isRefreshTokenValid) {
          token = await this.refreshJWTToken();
        }

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          flushAccessToken();
        }

        return config;
      },
      (error: any) => Promise.reject(error)
    );
  }

  static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  static getAxios(): AxiosInstance {
    return Api.getInstance().axiosInstance;
  }
  static setAuthToken(token: string | null) {
    //@ts-ignore
    Api.getAxios().defaults.headers.Authorization = `Bearer ${token}`;
  }
  static get<T = any>(url: string, params: Record<string, any> = {}, config: AxiosRequestConfig = {}): AxiosPromise<T> {
    return Api.getAxios().get(url, { params, ...config });
  }

  static delete<T = any>(
    url: string,
    params: Record<string, any> = {},
    config: AxiosRequestConfig = {}
  ): AxiosPromise<T> {
    return Api.getAxios().delete(url, { params, ...config });
  }

  static post<T = any>(url: string, data?: Record<string, any>, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().post(url, data, config);
  }

  static put<T = any>(url: string, data?: Record<string, any>, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().put(url, data, config);
  }

  static patch<T = any>(url: string, data?: Record<string, any>, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().patch(url, data, config);
  }
}
