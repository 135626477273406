import { useParams } from 'react-router-dom';
import { PageContenEnum } from 'utils/contstants';
import { TASKS_TEMPLATE_URL } from 'store/api';

import { ListSection } from 'sections/ListSection';
import { ShowSection } from 'sections/ShowSection';
import { EditSection } from 'sections/EditSection';
import { AddSection } from 'sections/AddSection';
import { TASK_TEMPALTE_FORM_CONFIG } from './task-template-form.config';

export const TaskTemplates = () => {
  const { content, id }: any = useParams();

  if (!content || content === PageContenEnum.LIST) {
    return <ListSection url={TASKS_TEMPLATE_URL} columnName="task-templates" />;
  } else if (content === PageContenEnum.SHOW) {
    return <ShowSection url={TASKS_TEMPLATE_URL} title="Task Template Details" />;
  } else if (content === PageContenEnum.EDIT) {
    return (
      <EditSection id={id} url={TASKS_TEMPLATE_URL} title="Edit Task Template" config={TASK_TEMPALTE_FORM_CONFIG} />
    );
  }

  return <AddSection title="Create Task Template" config={TASK_TEMPALTE_FORM_CONFIG} />;
};
