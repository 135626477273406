import { IAccountModel } from '../../types/models/AccountModel';
import { AppNetworkType } from '../../types/models/NetworkModel';
import { BaseSecureStorage } from './BaseSecureStorage';

export class AccountSecureStorage extends BaseSecureStorage {
  /** ACCOUNTS DB KEY **/
  private static ACCOUNTS_KEY = 'ACCOUNTS';

  /**
   * Creates a new account
   * @returns {Promise<AccountModel>}
   * @param account
   */
  public static async createNewAccount(account: IAccountModel): Promise<IAccountModel> {
    const accounts = await this.getAllAccounts();
    if (!accounts.find((el) => el.id === account.id && el.network === account.network)) {
      accounts.push(account);
    }
    return this.saveAccounts(accounts);
  }

  /**
   * Creates a new account
   * @returns {Promise<any>}
   * @param accounts
   */
  public static async saveAccounts(accounts: IAccountModel[]): Promise<any> {
    return this.secureSaveAsync(this.ACCOUNTS_KEY, JSON.stringify(accounts));
  }

  /**
   * Updates account
   * @param newAccount
   * @returns {Promise<*>}
   */
  public static async updateAccount(newAccount: IAccountModel): Promise<any> {
    const allAccounts = await this.getAllAccounts();
    const edited = allAccounts.map((account) => {
      if (account.id === newAccount.id) {
        return newAccount;
      } else {
        return account;
      }
    });
    return this.saveAccounts(edited);
  }

  /**
   * Removes an account by its id and network
   * @param id
   * @param network
   * @returns {Promise<*>}
   */
  public static async removeAccount(id: string, network: AppNetworkType): Promise<IAccountModel[]> {
    const allAccounts = await this.getAllAccounts();

    const filteredAccounts = allAccounts.filter((account) => !(account.id === id && account.network === network));
    await this.saveAccounts(filteredAccounts);
    return filteredAccounts;
  }

  /**
   * Returns all accounts
   * @returns {Promise<void>}
   */
  public static async getAllAccounts(): Promise<IAccountModel[]> {
    try {
      const accountsString: any = await this.secureRetrieveAsync(this.ACCOUNTS_KEY);
      return JSON.parse(accountsString) || [];
    } catch (e) {
      return [];
    }
  }

  /**
   * Get all the accounts
   * @returns {Promise<AccountModel[]>}
   */
  public static async getAllAccountsByNetwork(network: AppNetworkType): Promise<IAccountModel[]> {
    const allAccounts = await this.getAllAccounts();
    return allAccounts.filter((el) => el.network === network);
  }

  /**
   * Get all the accounts
   * @returns {Promise<AccountModel[]>}
   */
  public static async getAccountById(id: number, network: AppNetworkType): Promise<IAccountModel | null> {
    const accounts: any = await this.getAllAccountsByNetwork(network);
    return accounts.find((account: IAccountModel) => account.id === id);
  }

  /**
   * Clear all keys
   * @returns {Promise<string | null>}
   */
  public static clear(): Promise<any> {
    return this.removeKey(this.ACCOUNTS_KEY) || null;
  }
}
