import { createSlice, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { API_PATH } from 'api/apiPath';
import { IUserState, IUserData, IFetchUserUrlParams } from 'redux/user/types';

const initialState: IUserState = {
  data: {},
  error: null,
  loading: true,
  succeed: false,
};

export const getUserById$request = createAction('[USER]/getUserById$request', ({ id }: IFetchUserUrlParams) => ({
  payload: {
    url: API_PATH.USER.BY_ID.replace(':id', id),
    method: 'get',
  },
}));

const userSlice = createSlice({
  name: '[USER]',
  initialState,
  reducers: {
    getUserById$start: (state) => {
      state.loading = true;
      state.error = null;
    },
    getUserById$success: (state, { payload }: PayloadAction<IUserData>) => {
      state.loading = false;
      state.succeed = true;
      state.data = payload;
    },
    getUserById$error: (state, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.error = payload.response.data;
    },
  },
});

export const { getUserById$start, getUserById$success, getUserById$error } = userSlice.actions;
export const { reducer } = userSlice;
